<template>
    <h3 />
</template>

<script>
import AbstractTriggerTypeConfig from '@/views/trigger/TriggerTypeConfig/AbstractTriggerTypeConfig';

export default {
    name: 'Placeholder',
    extends: AbstractTriggerTypeConfig,
};
</script>
