<template>
    <SrInputBox :label="label" :active="active" @click="toggleActive">
        {{ placeholder }}
        <template #after>
            <VIcon>{{ activeIndicatorIcon }}</VIcon>
        </template>
    </SrInputBox>
</template>
<script>
import { SrInputBox } from '@ads/design-system';

export default {
    name: 'Activator',
    components: { SrInputBox },
    model: {
        prop: 'value',
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            internalValue: null,
        };
    },
    computed: {
        activeIndicatorIcon() {
            return this.internalValue ? 'mdi-chevron-left' : 'mdi-chevron-right';
        },
    },
    watch: {
        '$attrs.value': {
            handler(value) {
                this.internalValue = value;
            },
        },
    },
    methods: {
        toggleActive() {
            this.internalValue = !this.internalValue;
            this.$emit('input', this.internalValue);
        },
    },
};
</script>
