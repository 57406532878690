<template>
    <div class="page-view">
        <SrBodyText color="dark-grey--text"> This trigger fires on </SrBodyText>
        <VRadioGroup v-model="config.firesOnAllPages" row hide-details>
            <SrInputBox @click="config.firesOnAllPages = true">
                <SrRadioButton label="All Page Views" :value="true" />
            </SrInputBox>
            <SrInputBox @click="config.firesOnAllPages = false">
                <SrRadioButton label="Some Page Views" :value="false" />
            </SrInputBox>
        </VRadioGroup>
    </div>
</template>

<script>
import './PageView.scss';

import { SrBodyText, SrInputBox, SrRadioButton } from '@ads/design-system';
import AbstractTriggerTypeConfig from '../AbstractTriggerTypeConfig';

export default {
    name: 'PageView',
    components: { SrBodyText, SrRadioButton, SrInputBox },
    extends: AbstractTriggerTypeConfig,
    data() {
        return {
            config: {
                firesOnAllPages: true,
            },
        };
    },
    computed: {
        isValid() {
            return true;
        },
    },
    mounted() {
        if (this.value && Object.prototype.hasOwnProperty.call(this.value, 'firesOnAllPages')) {
            this.config.firesOnAllPages = this.value.firesOnAllPages;
        }
    },
};
</script>
