<template>
    <PageLayout>
        <template #header-content>
            <div class="close-icon-wrapper" @click="close">
                <SrIcon class="pa-1" icon="close" size="xs" />
            </div>
        </template>
        <template #header-loading>
            <VProgressLinear v-if="isLoading || isSaving" :indeterminate="true" :value="0" color="blue" />
        </template>
        <template #footer-content>
            <ErrorBoxComponent v-model="error" outlined dismissible @close="resetError" />
            <SrButton
                type="secondary"
                class="ml-auto page-layout__submit-button"
                :disabled="!isSaveable || isSaving || !formIsValid"
                :loading="isSaving"
                @click="save"
            >
                {{ isNewTrigger ? 'Create' : 'Save' }}
            </SrButton>
        </template>
        <VRow class="mt-6">
            <VCol cols="12" class="pb-0 pt-1">
                <SrHeadline weight="bold"> {{ isNewTrigger ? 'Create' : 'Save' }} Trigger </SrHeadline>
            </VCol>
            <VCol v-if="trigger" cols="12" class="pt-0">
                <VForm ref="form" v-model="formIsValid">
                    <VRow>
                        <VCol cols="4">
                            <SrInput
                                v-model="trigger.name"
                                label="Trigger Name"
                                placeholder="Trigger Name"
                                show-is-valid-indicator
                                required
                            />
                            <SrHeadline level="3" class="mb-8"> Trigger Type </SrHeadline>
                            <Activator
                                v-model="showTypeSelector"
                                :active="showTypeSelector"
                                :placeholder="activatorPlaceholderText"
                                label="Choose tag type to begin setup"
                            />
                            <div v-if="trigger.type" class="mt-8 trigger-type-config">
                                <component :is="triggerTypeConfigComponent" ref="triggerTypeConfig" v-model="trigger.config" />
                            </div>
                        </VCol>
                        <VCol cols="4" class="trigger-creator__selector-container">
                            <transition name="translateX">
                                <ListSelector
                                    v-if="showTypeSelector"
                                    v-model="trigger.type"
                                    :items="triggerTypeSelectorConfig"
                                    heading="Triggers"
                                    active
                                />
                            </transition>
                        </VCol>
                        <VCol v-if="showPredicateConfigurator" cols="12">
                            <TriggerPredicateConfigurator v-model="trigger.predicate" />
                        </VCol>
                    </VRow>
                </VForm>
            </VCol>
        </VRow>
    </PageLayout>
</template>

<script>
import './TriggerForm.scss';

import { Activator } from '@/views/Activator';
import ListSelector from '@/views/ListSelector';
import { SrButton, SrHeadline, SrInput, SrIcon } from '@ads/design-system';
import PageLayout from '@/views/layouts/PageLayout';
import TriggerPredicateConfigurator from '@/views/trigger/TriggerPredicateConfiguration/TriggerPredicateConfigurator';
import TriggerRepository from '@/store/TriggerRepository';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';
import triggerTypeConfigService from './TriggerTypeConfig/TriggerTypeConfigService';
import triggerTypeLabels from './triggerTypeLabels';
import triggerTypes from './triggerTypes';

export default {
    name: 'TriggerForm',
    components: {
        ErrorBoxComponent,
        TriggerPredicateConfigurator,
        SrIcon,
        PageLayout,
        Activator,
        ListSelector,
        SrButton,
        SrInput,
        SrHeadline,
    },
    mixins: [ErrorBoxMixin],
    props: {
        triggerId: {
            type: Number,
            default: null,
        },
        containerId: {
            type: String,
            default: null,
        },
        pixelId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            trigger: null,
            initialTrigger: null,
            showTypeSelector: false,
            isSaving: false,
            formIsValid: false,
            triggerTypeSelectorConfig: [
                {
                    type: triggerTypes.PAGE_VIEW_TRIGGER,
                    label: triggerTypeLabels.PAGE_VIEW_TRIGGER,
                    description: 'Triggers when the history changes. (Mainly on Single Page Applications).',
                    icon: 'file',
                },
                {
                    type: triggerTypes.DOMAIN_BASED_TIMED_TRIGGER,
                    label: triggerTypeLabels.DOMAIN_BASED_TIMED_TRIGGER,
                    description: 'Triggers after a specific amount of time has passed.',
                    icon: 'stop',
                },
            ],
        };
    },
    computed: {
        isNewTrigger() {
            return this.triggerId === null;
        },
        isSaveable() {
            try {
                if (this.trigger === null) {
                    return false;
                }

                if (this.trigger.name === '' || this.trigger.name === null || this.isInitialTrigger()) {
                    return false;
                }

                if (this.trigger.type === null) {
                    return false;
                }

                return !(this.$refs.triggerTypeConfig && !this.$refs.triggerTypeConfig.isValid);
            } catch (error) {
                console.error(error);
                return false;
            }
        },
        activatorPlaceholderText() {
            return this.selectedTrigger ? this.selectedTrigger.label : 'Select trigger';
        },
        selectedTrigger() {
            if (!this.trigger.type) {
                return null;
            }

            return this.triggerTypeSelectorConfig.find((triggerType) => triggerType.type === this.trigger.type);
        },
        triggerTypeConfigComponent() {
            return triggerTypeConfigService.getComponentForType(this.trigger.type);
        },
        showPredicateConfigurator() {
            return (
                this.trigger.type &&
                this.trigger.type === 'PAGE_VIEW_TRIGGER' &&
                this.trigger.config &&
                this.trigger.config.firesOnAllPages === false
            );
        },
    },
    watch: {
        triggerId: {
            async handler(triggerId) {
                this.isLoading = true;
                if (triggerId === null) {
                    await this.resetTrigger();
                } else {
                    await this.setTriggerByTriggerIdAndContainerId(triggerId, this.containerId);
                }
                this.isLoading = false;
            },
            immediate: true,
        },
        trigger: {
            deep: true,
            handler(newValue, oldValue) {
                if (oldValue === null) {
                    this.initialTrigger = JSON.parse(JSON.stringify(newValue));
                }
            },
        },
        'trigger.type': {
            handler(type) {
                if (type !== triggerTypes.PAGE_VIEW_TRIGGER) {
                    this.removeTriggerPredicate();
                }
                this.showTypeSelector = false;
            },
        },
        'trigger.config.firesOnAllPages': {
            handler(newValue) {
                if (newValue) {
                    this.removeTriggerPredicate();
                }
            },
        },
    },
    methods: {
        resetTrigger() {
            this.trigger = {
                id: null,
                name: null,
                type: null,
                config: null,
            };
        },
        isInitialTrigger() {
            return JSON.stringify(this.trigger) === JSON.stringify(this.initialTrigger);
        },
        async setTriggerByTriggerIdAndContainerId(triggerId, containerId) {
            this.trigger = await TriggerRepository.getByTriggerIdAndContainerId(triggerId, containerId);
        },
        removeTriggerPredicate() {
            this.$delete(this.trigger, 'predicate');
        },
        async save() {
            if (!this.isSaveable) {
                this.setError('Save is not allowed!');
                return;
            }

            this.isSaving = true;

            const saveTriggerPayload = {
                trigger: this.trigger,
                containerId: this.containerId,
            };
            // replace alerts with Notifications!
            this.$store
                .dispatch('saveTriggerWithContainerId', saveTriggerPayload)
                .then(await this.handleCreatedTrigger)
                .catch((error) => this.setError(error))
                .finally(() => {
                    this.isSaving = false;
                });
        },
        close() {
            this.$emit('close');
        },
        async handleCreatedTrigger(trigger) {
            if (!this.pixelId) {
                await this.$store
                    .dispatch('getAllTriggersByContainerId', this.containerId)
                    .catch((error) => this.setError(error));
                this.isSaving = false;
                this.close();
            } else {
                await this.assignTriggerToPixel(trigger);
            }
        },
        async assignTriggerToPixel(trigger) {
            this.isSaving = true;
            const triggerId = trigger.id;
            this.$store
                .dispatch('assignTriggerToPixel', {
                    pixelId: this.pixelId,
                    triggerId,
                    containerId: this.containerId,
                })
                .then(async () => {
                    await this.$store.dispatch('fetchPixelsForContainer', this.containerId);
                    this.isSaving = false;
                    this.close();
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.setError(error);
                });
        },
    },
};
</script>
