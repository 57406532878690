import 'reflect-metadata';
import { Expose, Type } from 'class-transformer';
import Trigger from '@/models/Trigger';
import Pixel from '@/models/Pixel';
import Variable from '@/models/Variable';
import IContainerFilter from '@/models/IContainerFilter';
import BusinessEntity from '@/models/BusinessEntity';

enum PLATFORM {
    WEB = 'WEB',
    IOS = 'IOS',
    ANDROID = 'ANDROID',
}

export default class Container {
    @Expose({ name: 'encodedPublicId' })
    @Expose()
    id: number | null;

    publicId: number;

    userId: string;

    @Expose()
    name: string;

    @Expose()
    country: string;

    @Expose()
    growthAnalyticsId: string;

    @Expose()
    hasEnabledGrowthAnalyticsId: boolean;

    @Expose()
    facebookPixelId: string;

    @Expose()
    hasEnabledFacebookPixelId: boolean;

    @Expose()
    snapchatPixelId: string;

    @Expose()
    hasEnabledSnapchatPixelId: boolean;

    @Expose()
    twitterPixelId: string;

    @Expose()
    hasEnabledTwitterPixelId: boolean;

    @Expose()
    f8OperatorPublicAccessKey: string;

    @Expose()
    sportFilter: IContainerFilter;

    @Expose()
    casinoFilter: IContainerFilter;

    @Expose()
    platform: PLATFORM;

    @Expose()
    businessEntityId: number;

    @Expose()
    @Type(() => BusinessEntity)
    businessEntity: BusinessEntity;

    @Expose()
    holdingId: string;

    @Expose()
    @Type(() => Trigger)
    triggers: Trigger[];

    @Expose()
    @Type(() => Pixel)
    pixels: Pixel[];

    @Expose()
    @Type(() => Variable)
    variables: Variable[];

    createdAt: Date;

    updatedAt: Date;
}
