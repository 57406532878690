<template>
    <VRow class="mt-6">
        <VCol cols="12">
            <SrHeadline level="2" weight="bold"> Select Tag </SrHeadline>
        </VCol>
        <VCol cols="12">
            <VRadioGroup v-model="pixel.tagType" row>
                <SrRadioButton value="img" label="Image" />
                <SrRadioButton value="js" label="Javascript" />
            </VRadioGroup>
        </VCol>
        <VCol v-if="pixel.id" cols="12">
            <SrInput
                v-for="pixelCodeBlock of pixelCodeBlocks"
                :key="pixelCodeBlock.code"
                :label="pixelCodeBlock.label"
                class="pixel-code-block"
                :value="pixelCodeBlock.code"
                append-icon="copy"
                readonly
                @click:append="copyToClipboard(pixelCodeBlock.code)"
            />
        </VCol>
    </VRow>
</template>

<script>
import { SrHeadline, SrRadioButton, SrInput } from '@ads/design-system';
import PixelCodeBlockGenerator from '@/views/PixelForm/PixelCodeBlockGenerator';
import PixelCodeLoader from './PixelCodeLoader';

export default {
    name: 'PixelTag',
    components: { SrHeadline, SrRadioButton, SrInput },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            pixel: this.value,
            pixelCodeBlockGenerator: new PixelCodeBlockGenerator(new PixelCodeLoader()),
        };
    },
    computed: {
        pixelCodeBlocks() {
            return this.pixelCodeBlockGenerator.generate(this.pixel);
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.pixel = value;
            },
        },
        pixel: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        copyToClipboard(code) {
            window.navigator.clipboard.writeText(code);
        },
    },
};
</script>

<style>
.v-application--is-ltr .v-text-field .v-input__append-inner {
    margin-left: 8px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
</style>
