<template>
    <SrDataPlaceholder :icon="icon" :title="title" :text="text" class="outlined pa-12">
        <slot />
    </SrDataPlaceholder>
</template>

<script>
import { SrDataPlaceholder } from '@ads/design-system';

export default {
    name: 'Placeholder',
    components: { SrDataPlaceholder },
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    },
};
</script>
