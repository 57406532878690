<template>
    <PageLayout>
        <template #header-content>
            <div class="close-icon-wrapper" @click="close">
                <SrIcon class="pa-1" icon="close" size="xs" />
            </div>
        </template>
        <template #header-loading>
            <VProgressLinear v-if="isLoading || saveInProgress" :indeterminate="true" :value="0" color="blue" />
        </template>
        <template #footer-content>
            <ErrorBoxComponent v-model="error" outlined dismissible @close="resetError" />
            <SrButton
                type="secondary"
                class="ml-auto page-layout__submit-button"
                :loading="saveInProgress"
                :disabled="saveInProgress || !formIsValid"
                @click="save"
            >
                {{ isNewPixel ? 'Create' : 'Save' }}
            </SrButton>
        </template>
        <VRow class="mt-6">
            <VCol cols="12">
                <h2 class="brand-blue--text">{{ isNewPixel ? 'Create' : 'Edit' }} Pixel</h2>
            </VCol>
            <VCol cols="12" class="pixel-form">
                <VForm v-if="pixelLoaded" ref="form" v-model="formIsValid" @submit.prevent="save">
                    <VRow>
                        <VCol cols="4">
                            <h3 class="mb-8 pixel-form__action-title">Pixel</h3>
                            <Field
                                v-model="pixel.id"
                                :field="pixelIdOptions"
                                :validation-rules="validations[pixelIdOptions.name]"
                            />
                            <Field
                                v-model="pixel.name"
                                :field="pixelNameOptions"
                                :validation-rules="validations[pixelNameOptions.name]"
                            />
                            <Field
                                v-show="shouldShowTwitterInput"
                                v-model="twitterEventId"
                                :field="twitterFieldConfig"
                                :validation-rules="validations['twitterEventId']"
                            />
                        </VCol>
                        <VCol cols="4" class="ml-10">
                            <h3 class="mb-8 pixel-form__action-title">Parameters</h3>
                            <SrSelect
                                v-if="isConversionPixel"
                                v-model="pixel.pixelConversionTypeId"
                                :items="pixelConversionTypes"
                                placeholder="Conversion Type"
                                label="Conversion Type"
                                hint="Conversion Type"
                                item-value="id"
                                item-text="name"
                                required
                                attach
                            />
                            <SrSelect
                                v-if="pixel.pixelConversionTypeId === conversionTypes.VIDEO_AUDIENCE"
                                v-model="pixel.videoEventType.id"
                                :items="videoEventTypes"
                                placeholder="Video Event Type"
                                label="Video Event Type"
                                hint="Video Event Type"
                                item-value="id"
                                item-text="name"
                                required
                                attach
                            />
                            <SrInput
                                v-if="!isSegmentPixel"
                                v-model="pixel.postViewDays"
                                placeholder="Post View Days"
                                label="Post View Days"
                                hint="Post View Days"
                                :rules="validations['postViewDays']"
                            />
                            <SrInput
                                v-if="!isSegmentPixel"
                                v-model="pixel.postClickDays"
                                placeholder="Post Click Days"
                                label="Post Click Days"
                                hint="Post Click Days"
                                :rules="validations['postClickDays']"
                            />
                            <SrInput
                                v-model="pixel.expirationDays"
                                placeholder="Expiration Days"
                                label="Expiration Days"
                                hint="Expiration Days"
                                :rules="validations['expirationDays']"
                            />
                        </VCol>
                    </VRow>
                    <VRow class="mt-0">
                        <VCol>
                            <div class="pt-0 pb-0 is-server-to-server-pixel">
                                <SrCheckbox
                                    v-model="isServerToServerPixel"
                                    hide-details
                                    :input-value="isServerToServerPixel"
                                    label="S2S"
                                />
                            </div>
                            <div class="pt-0 pixel-event-disabled">
                                <SrCheckbox
                                    v-model="pixel.eventDisabled"
                                    hide-details
                                    :input-value="pixel.eventDisabled"
                                    :disabled="pixel.isServerToServer"
                                    label="Disabled for tracking with Pixel Manager"
                                />
                            </div>
                            <div v-if="isGrowthAnalyticsAvailable" class="pt-0 pb-0 growth-analytics">
                                <SrCheckbox
                                    v-model="pixel.trackGrowthAnalytics"
                                    hide-details
                                    :input-value="pixel.trackGrowthAnalytics"
                                    label="Track with Growth Analytics"
                                />
                            </div>
                        </VCol>
                    </VRow>
                    <PixelTag v-model="pixel" />
                </VForm>
            </VCol>
        </VRow>
    </PageLayout>
</template>
<script>
import { SrButton, SrCheckbox, SrIcon, SrSelect, SrInput } from '@ads/design-system';
import Pixel from '@/models/Pixel';
import { plainToClass } from 'class-transformer';
import PageLayout from '@/views/layouts/PageLayout';
import PixelRepository from '@/store/PixelRepository';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import { ROUTE_NAMES } from '@/routes/routeNames';
import { PIXEL_TAG_TYPE, PIXEL_TYPE } from './PixelCodeLoader';
import { PIXEL_CONVERSION_TYPE_IDS } from './types';
import PixelTag from './PixelTag';
import Field from './Field';

export default {
    name: 'PixelForm',
    components: {
        ErrorBoxComponent,
        PixelTag,
        PageLayout,
        SrIcon,
        Field,
        SrButton,
        SrCheckbox,
        SrSelect,
        SrInput,
    },
    mixins: [ErrorBoxMixin],
    props: {
        pixelId: {
            type: Number,
            default: null,
        },
        containerId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            conversionTypes: PIXEL_CONVERSION_TYPE_IDS,
            formIsValid: true,
            validations: {
                postViewDays: [this.numberValidationRule],
                postClickDays: [this.numberValidationRule],
                expirationDays: [this.numberValidationRule, this.numberBoundariesValidationRule(0, 364)],
                twitterEventId: [this.validateTwitterEventIdRule],
            },
            pixel: {},
            isLoading: false,
            saveInProgress: false,
            twitterLabelMapping: {
                2: 'Search', // login
                3: 'Checkout initiated', // registration started
                4: 'Lead', // registration finished
                5: 'Purchase', // first time deposit
                6: 'Subscribe', // deposit
                11: 'Added payment info', // bet placed
                13: 'Content view', // retargeting
            },
        };
    },
    computed: {
        pixelLoaded() {
            return Boolean(Object.keys(this.pixel).length);
        },
        isServerToServerPixel: {
            get() {
                return this.pixel.isServerToServer;
            },
            set(newValue) {
                this.pixel.eventDisabled = newValue;
                this.pixel.isServerToServer = newValue;
            },
        },
        pixels() {
            return this.$store.getters.pixels;
        },
        isNewPixel() {
            return this.pixelId === null;
        },
        isGrowthAnalyticsAvailable() {
            if (this.pixel.pixelConversionTypeId) {
                return [4, 5, 6, 11].includes(this.pixel.pixelConversionTypeId);
            }
            return false;
        },
        isConversionPixel() {
            return this.pixel.pixelType === PIXEL_TYPE.CONVERSION;
        },
        isSegmentPixel() {
            return this.pixel.pixelType === PIXEL_TYPE.SEGMENT;
        },
        pixelIdOptions() {
            return {
                type: 'text',
                name: 'id',
                placeholder: 'ID',
                label: 'ID',
                hint: 'Pixel ID',
                required: false,
                disabled: true,
                show: true,
            };
        },
        pixelNameOptions() {
            return {
                type: 'text',
                name: 'name',
                placeholder: 'Pixel Name',
                label: 'Pixel Name',
                hint: 'Pixel name',
                required: true,
                show: true,
            };
        },
        twitterFieldConfig() {
            return {
                type: 'text',
                name: 'twitterEventId',
                placeholder: 'tw-abcde-12345',
                label: this.twitterLabel,
                hint: this.twitterLabel,
                required: false,
                disabled: this.shouldDisableTwitterInput,
                show: this.shouldShowTwitterInput,
            };
        },
        videoEventTypes() {
            return this.$store.getters.videoEventTypes;
        },
        pixelConversionTypes() {
            return this.$store.getters.pixelConversionTypes;
        },
        twitterLabel() {
            const twitterLabel = this.twitterLabelMapping[this.pixel.pixelConversionTypeId];
            if (!twitterLabel) {
                return '';
            }
            return `Twitter ${twitterLabel} ID`;
        },
        shouldShowTwitterInput() {
            return Boolean(this.twitterLabel);
        },
        shouldDisableTwitterInput() {
            return !this.$store.getters.currentContainer.hasEnabledTwitterPixelId;
        },
        twitterEventId: {
            get() {
                if (!this.$store.getters.currentContainer.twitterPixelId || !this.pixel.twitterEventId) {
                    return '';
                }
                return `tw-${this.$store.getters.currentContainer.twitterPixelId}-${this.pixel.twitterEventId}`;
            },
            set(newValue) {
                const twitterEventId = newValue.replace(`tw-${this.$store.getters.currentContainer.twitterPixelId}-`, '');
                if (!twitterEventId) {
                    this.pixel.twitterEventId = null;
                    return;
                }
                this.pixel.twitterEventId = twitterEventId;
            },
        },
    },
    watch: {
        'pixel.pixelConversionTypeId': {
            handler(newValue, oldValue) {
                if (oldValue) {
                    this.pixel.twitterEventId = null;
                }
                if (!this.isGrowthAnalyticsAvailable) {
                    this.pixel.trackGrowthAnalytics = false;
                }

                if (this.pixel.pixelConversionTypeId !== this.conversionTypes.VIDEO_AUDIENCE) {
                    this.pixel.videoEventType = {
                        id: null,
                    };
                }
            },
        },
    },
    async created() {
        this.isLoading = true;
        if (this.pixelId === null) {
            this.resetPixel();
        } else {
            await this.setPixelByPixelIdAndContainerId(this.pixelId, this.containerId);
        }
        this.isLoading = false;
    },
    methods: {
        resetPixel() {
            this.pixel = {
                id: null,
                name: '',
                twitterEventId: null,
                eventDisabled: false,
                isServerToServer: false,
                holdingId: 1,
                businessEntityId: 1,
                pixelConversionType: {},
                pixelConversionTypeId: null,
                videoEventType: { id: null },
                postViewDays: 7,
                postClickDays: 30,
                expirationDays: 30,
                tagType: PIXEL_TAG_TYPE.JAVASCRIPT.toString(),
                pixelType: PIXEL_TYPE.CONVERSION.toString(),
            };
        },

        async setPixelByPixelIdAndContainerId(pixelId, containerId) {
            try {
                this.pixel = await PixelRepository.getByPixelIdAndContainerId(pixelId, containerId);
            } catch (error) {
                this.setError(error);
            }
        },

        close() {
            this.$emit('close');
        },

        validate() {
            this.$refs.form.validate();
        },

        numberValidationRule(value) {
            return !/^[0-9]\d*$/.test(value) ? 'Input has to be a valid Number' : true;
        },

        numberBoundariesValidationRule(min, max) {
            return (value) => (value < min || value > max ? `Input has to be between ${min} and ${max}` : true);
        },

        validateTwitterEventIdRule(value) {
            if (value === '') {
                return true;
            }

            const twitterPixelId = this.$store.getters.currentContainer.twitterPixelId;
            const pattern = new RegExp(`^tw-${twitterPixelId}-[0-9a-zA-Z]{5}$`);

            return !pattern.test(value) && this.shouldShowTwitterInput ? 'Input has to be a valid Twitter Event ID' : true;
        },

        save() {
            this.validate();

            if (this.formIsValid) {
                const pixel = plainToClass(Pixel, this.pixel);
                const containerId = this.$store.getters.currentContainer.id;
                this.saveInProgress = true;

                this.$store
                    .dispatch('savePixel', { pixel, containerId })
                    .then(async (savedPixel) => {
                        await this.$store.dispatch('fetchPixelsForContainer', containerId).catch((error) => this.setError(error));

                        if (this.isNewPixel) {
                            this.navigateToTriggerPlaceholder(savedPixel);
                        } else {
                            this.close();
                        }
                    })
                    .catch((error) => this.setError(error))
                    .finally(() => {
                        this.saveInProgress = false;
                    });
            }
        },

        navigateToTriggerPlaceholder({ id }) {
            const { params } = this.$route;
            this.$router.push({
                name: ROUTE_NAMES.TRIGGER_ASSIGN,
                params: { ...params, pixelId: id },
            });
        },
    },
};
</script>
