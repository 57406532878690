import { plainToClass } from 'class-transformer';
import { AxiosRequestConfig, Method } from 'axios';
import Container from '@/models/Container';
import { API_URL } from '@/config';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';

class ContainerRepository {
    private requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getById(id): Promise<Container> {
        const data = await this.request('GET', `${API_URL}/containers/${id}`);

        return plainToClass(Container, data);
    }

    async getAll(): Promise<Container[]> {
        const data = await this.request('GET', `${API_URL}/containers`);

        return plainToClass(Container, data as []);
    }

    async getTag(id): Promise<string> {
        const data = await this.request('GET', `${API_URL}/containers/${id}/integration-script`);

        return data.replace(/ /g, '').replace(/\r?\n|\r/g, '');
    }

    async create(container: Container): Promise<Container> {
        const data = await this.request('POST', `${API_URL}/containers`, container);

        return plainToClass(Container, data);
    }

    async publish(containerId: number) {
        return this.request('POST', `${API_URL}/containers/${containerId}/publish`);
    }

    request(method: Method, url: string, data?: any, params?: AxiosRequestConfig): Promise<any> {
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'container',
            action: ACTION_NAME.READ,
        };

        if (method === 'GET') {
            return this.requestService.get(url, { ...options, action: ACTION_NAME.READ });
        }
        if (method === 'POST') {
            return this.requestService.post(url, data, { ...options, action: ACTION_NAME.CREATE });
        }
        if (method === 'PUT') {
            return this.requestService.put(url, data, { ...options, action: ACTION_NAME.EDIT });
        }
        return null;
    }

    async save(container, containerId: string): Promise<any> {
        const baseUrl = `${API_URL}/containers/${containerId}`;
        const data = await this.request('PUT', baseUrl, container);
        // class-transformer fails here parsing publicId into id property for some reason
        const savedContainer = plainToClass(Container, data);

        savedContainer.id = data.publicId;
        return savedContainer;
    }
}

export default new ContainerRepository(new RequestService());
