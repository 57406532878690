<template>
    <div class="mb-2">
        <SrInput
            v-if="field.type === 'text' && !field.display"
            :placeholder="field.placeholder"
            :label="field.label"
            :required="field.required"
            :hint="field.hint"
            :disabled="field.disabled"
            :rules="validationRules"
            :value="value"
            @input="emitInput"
        />
        <SrSelect
            v-if="field.type === 'select' && !field.display"
            :multiselect="field.multiselect"
            :placeholder="field.placeholder"
            :label="field.label"
            :required="field.required"
            :hint="field.hint"
            :items="field.items"
            :value="value"
            :rules="validationRules"
            :disabled="field.disabled"
            item-value="id"
            item-text="name"
            attach
            @change="emitInput"
        />
    </div>
</template>

<script>
import { SrInput, SrSelect } from '@ads/design-system';

export default {
    name: 'Field',
    components: { SrSelect, SrInput },
    props: {
        validationRules: {
            type: Array,
            default: () => [],
        },
        value: {
            type: null,
            default: '',
        },
        field: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        display: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        emitInput(value) {
            this.$emit('input', value);
        },
    },
};
</script>
