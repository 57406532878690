<template>
    <PageLayout>
        <template #header-content>
            <div class="close-icon-wrapper" @click="close">
                <SrIcon class="pa-1" icon="close" size="xs" @click="close" />
            </div>
        </template>
        <template #footer-content>
            <ErrorBoxComponent v-model="error" outlined dismissible @close="resetError" />
            <SrButton type="secondary" class="ml-auto page-layout__submit-button" :to="{ name: createAssignTriggerRouteName }">
                Create
            </SrButton>
        </template>
        <SrHeadline>Triggers</SrHeadline>
        <Placeholder
            v-if="!isLoading && !triggersAvailable"
            icon="triggers"
            title="No Triggers Available!"
            text="Create trigger to be able to fire pixels and get valuable insights."
        >
            <SrButton type="secondary" class="mt-2" :to="{ name: createAssignTriggerRouteName }"> Create </SrButton>
        </Placeholder>
        <TriggersTable
            v-else
            :is-loading="isLoading"
            :triggers="triggers"
            :pixel="currentPixel"
            :is-assignable="true"
            @trigger-clicked="handleTriggerClickedEvent"
            @trigger-deleted="handleTriggerDeletedEvent"
        />
    </PageLayout>
</template>

<script>
import { copyText } from '@/config';
import Placeholder from '@/views/Placeholder';
import PageLayout from '@/views/layouts/PageLayout';
import TriggersTable from '@/views/TriggersTable';
import { SrIcon, SrHeadline, SrButton } from '@ads/design-system';
import TriggerRepository from '@/store/TriggerRepository';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'AssignTrigger',
    components: {
        ErrorBoxComponent,
        SrHeadline,
        SrIcon,
        TriggersTable,
        PageLayout,
        Placeholder,
        SrButton,
    },
    mixins: [ErrorBoxMixin],
    props: {
        containerId: {
            type: [Number, String],
            required: true,
        },
        pixelId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            show: true,
            title: copyText.en.containerPlaceholder.title,
            isLoading: false,
            triggers: [],
            createAssignTriggerRouteName: ROUTE_NAMES.TRIGGER_CREATE_ASSIGN,
        };
    },
    computed: {
        currentPixel() {
            return this.$store.getters.pixelById(Number(this.pixelId));
        },

        triggersAvailable() {
            return this.triggers.length !== 0;
        },
    },

    async mounted() {
        await this.loadTriggers();
    },
    methods: {
        async loadTriggers() {
            this.isLoading = true;
            try {
                this.triggers = await TriggerRepository.getAllByContainerId(this.containerId);
            } catch (error) {
                this.setError(error);
            }
            this.isLoading = false;
        },
        close() {
            this.$emit('close');
        },
        async handleTriggerDeletedEvent() {
            await this.loadTriggers();
        },
        handleTriggerClickedEvent(trigger) {
            this.isLoading = true;
            const isAssigned = this.$store.getters.isTriggerAssignedToPixel(trigger, this.currentPixel);
            const action = isAssigned ? 'unassignTriggerFromPixel' : 'assignTriggerToPixel';

            this.$store
                .dispatch(action, {
                    pixelId: this.pixelId,
                    triggerId: trigger.id,
                    containerId: this.containerId,
                })
                .then(async () => {
                    await this.$store.dispatch('fetchPixelsForContainer', this.containerId);
                    this.close();
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.setError(error);
                    this.isLoading = false;
                });
        },
    },
};
</script>
