import { Expose, Type } from 'class-transformer';
import 'reflect-metadata';

export default class Trigger {
    @Expose()
    id: number;

    @Expose()
    name: string;

    @Expose()
    type: string;

    @Expose()
    config: object;

    @Expose()
    @Type(() => Date)
    createdAt: Date;

    @Expose()
    @Type(() => Date)
    updatedAt: Date;
}
