<template>
    <VHover v-slot="{ hover }">
        <VSheet color="primary" :class="{ 'on-hover': hover }" outlined rounded>
            <VCard max-width="150" outlined :color="hover ? '#eff3ff' : '#ffffff'">
                <v-card-title>
                    <SrIcon size="ml" :icon="icon" />
                </v-card-title>
                <v-card-text>
                    <div class="d-flex flex-column mt-8 platform-button__container">
                        <div>
                            <p class="platform-button__name mb-0 brand-blue--text">
                                {{ name }}
                            </p>
                        </div>
                        <div class="platform-button__text d-flex align-center">
                            <p class="mb-0 caption">
                                {{ text }}
                            </p>
                        </div>
                    </div>
                </v-card-text>
            </VCard>
        </VSheet>
    </VHover>
</template>

<script>
import { SrIcon } from '@ads/design-system';

export default {
    name: 'PlatformButton',
    components: { SrIcon },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
    },

    computed: {
        buttonCssClasses() {
            const cssClasses = ['pa-4', 'pt-8', 'platform-button', 'd-flex', 'flex-column'];
            const activeClass = 'platform-button--active';
            if (this.active) {
                cssClasses.push(activeClass);
            }
            return cssClasses;
        },
    },
};
</script>
