<template>
    <VApp id="microfrontend-application-container" class="pixel-manager">
        <SrAppContainer id="pixel-manager-container" :hide-sidebar="!isSidebarVisible">
            <template #sidebar>
                <SideNavigation />
            </template>
            <template #content>
                <div>
                    <ContainerHeader />
                    <ContainerSelector />
                    <slot />
                </div>
            </template>
        </SrAppContainer>
    </VApp>
</template>

<script>
import { SrAppContainer } from '@ads/design-system';
import SideNavigation from '@/views/SideNavigation';
import ContainerHeader from '@/views/ContainerHeader';
import { mapGetters } from 'vuex';
import ContainerSelector from '@/views/ContainerSelector';

export default {
    name: 'AppLayout',
    components: { ContainerHeader, SrAppContainer, SideNavigation, ContainerSelector },
    data() {
        return {
            dialog: false,
            hideSidebar: false,
        };
    },
    computed: {
        ...mapGetters(['isSidebarVisible']),
    },
};
</script>

<style scoped>
.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
    max-height: 56px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
