<template>
    <SrMenu attach offset-y>
        <template #activator="{ on, attrs }">
            <SrButton color="blue lighten-5 blue--text text--lighten-1" v-bind="attrs" :loading="loading" v-on="on">
                <b class="blue--text">Bulk Create</b>
                <SrIcon icon="DropdownArrow" size="xs" class="ml-4" />
            </SrButton>
        </template>
        <SrList class="list">
            <SrListItem v-for="(item, index) in items" :key="index" :disabled="item.disabled" @click="emitClick(item)">
                <SrIcon :icon="item.icon" size="xs" class="icon" :disabled="item.disabled" />
                <SrHeadline level="4" class="action-title" :color="item.disabled ? 'gray-text' : ''">
                    {{ item.title }}
                </SrHeadline>
            </SrListItem>
        </SrList>
    </SrMenu>
</template>

<script>
import { SrIcon, SrHeadline, SrButton, SrMenu, SrList, SrListItem } from '@ads/design-system';

export default {
    name: 'DropdownButton',
    components: { SrIcon, SrHeadline, SrButton, SrMenu, SrList, SrListItem },
    props: {
        items: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        emitClick(item) {
            this.$emit('clicked', item);
        },
    },
};
</script>

<style scoped lang="scss">
.list {
    padding: 0 8px 0 0;
}

.icon {
    margin-top: -4px;
    margin-right: 10px;
}

.action-title {
    margin-top: 12px;
}
</style>
