interface IParameterValue {
    value: any;
}

export default class AtomicPredicate {
    type: string;

    filterCriteriaId: number;

    predicateDefinitionId: number;

    parameterValue: IParameterValue;

    constructor() {
        this.type = 'atomic';
        this.parameterValue = {
            value: null,
        };
    }
}
