import PixelCodeLoader from '@/views/PixelForm/PixelCodeLoader';
import Pixel from '@/models/Pixel';

type PixelCodeBlock = {
    label: string;
    code: string;
};

export default class PixelCodeBlockGenerator {
    private readonly pixelCodeLoader: PixelCodeLoader;

    constructor(pixelCodeLoader: PixelCodeLoader) {
        this.pixelCodeLoader = pixelCodeLoader;
    }

    generate(pixel: Pixel): PixelCodeBlock[] {
        const pixelCode = this.pixelCodeLoader.loadFor(pixel);
        if (pixel.isServerToServer) {
            return this.buildS2SCodeBlocks(pixelCode);
        }

        return [
            {
                label: 'Pixel Code',
                code: pixelCode,
            },
        ];
    }

    private buildS2SCodeBlocks(pixelCode: string): PixelCodeBlock[] {
        const [postViewCode, postClickCode] = pixelCode.trim().split('\n');

        return [
            {
                label: 'Post-View Conversion Tracking',
                code: postViewCode,
            },
            {
                label: 'Post-Click Conversion Tracking',
                code: postClickCode,
            },
        ];
    }
}
