import triggerTypes from '@/views/trigger/triggerTypes';
import { PageView } from './PageView';
import ElementClick from './ElementClick';
import HistoryChange from './HistoryChange';
import Observer from './Observer';
import { DomainBasedTimer } from './DomainBasedTimer';

class TriggerTypeConfigService {
    componentTypeMap = new Map();

    registerComponentByType(component, type) {
        this.componentTypeMap.set(type, component);
    }

    getComponentForType(type) {
        return this.componentTypeMap.get(type);
    }
}

const triggerTypeConfigService = new TriggerTypeConfigService();

triggerTypeConfigService.registerComponentByType(PageView, triggerTypes.PAGE_VIEW_TRIGGER);
triggerTypeConfigService.registerComponentByType(DomainBasedTimer, triggerTypes.DOMAIN_BASED_TIMED_TRIGGER);
triggerTypeConfigService.registerComponentByType(HistoryChange, triggerTypes.DOM_CONTENT_LOADED_TRIGGER);
triggerTypeConfigService.registerComponentByType(ElementClick, triggerTypes.ELEMENT_CLICK_TRIGGER);
triggerTypeConfigService.registerComponentByType(Observer, triggerTypes.OBSERVER_TRIGGER);

export default triggerTypeConfigService;
