import Vue from 'vue';
import App from './App.vue';
import DesignSystem, { vuetify } from '@ads/design-system';
import '@/scss/main.scss';
import VueRouter from 'vue-router';
import routes from '@/routes';
import singleSpaVue from 'single-spa-vue';
import store from './store';
import './vuetify-loader.conf.js';

Vue.use(VueRouter);
Vue.use(DesignSystem);


const router = new VueRouter({
    mode: 'history',
    base: '/pixel-manager',
    routes,
});

const vueLifeCycles = singleSpaVue({
    Vue,
    appOptions: {
        vuetify,
        render: h => h(App),
        store,
        router,
        el: '#microfrontend-application-container',
    },
});

export const bootstrap = vueLifeCycles.bootstrap;
export const mount = vueLifeCycles.mount;
export const unmount = vueLifeCycles.unmount;
