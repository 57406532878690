<template>
    <div class="predicate-configurator">
        <SrBodyText color="dark-grey--text">
            Fire this trigger when an Event occurs and all of these conditions are true
        </SrBodyText>
        <template v-if="value && value.children">
            <div v-for="(atomicPredicate, index) in value.children" :key="index">
                <PredicateItemConfigurator
                    :value="atomicPredicate"
                    :filter-criteria="filterCriteria"
                    :predicate-definitions="predicateDefinitions"
                    @update="(predicate) => updatePredicate(predicate, index)"
                    @add-predicate="addAtomicPredicate"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { SrBodyText } from '@ads/design-system';
import TriggerRepository from '@/store/TriggerRepository';
import ComplexPredicate from './ComplexPredicate';
import PredicateItemConfigurator from './PredicateItemConfigurator';
import AtomicPredicate from './AtomicPredicate';

export default {
    name: 'TriggerPredicateConfigurator',
    components: {
        PredicateItemConfigurator,
        SrBodyText,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            filterCriteria: [],
            predicateDefinitions: [],
        };
    },
    created() {
        this.ensurePredicateExists();
        this.loadFilterCriteria();
        this.loadPredicateDefinitions();
    },
    methods: {
        ensurePredicateExists() {
            if (!this.value) {
                const predicate = new ComplexPredicate();
                predicate.children.push(new AtomicPredicate());
                this.$emit('input', predicate);
            }
        },
        async loadFilterCriteria() {
            this.filterCriteria = await TriggerRepository.getAllFilterCriteriaForPageView();
        },
        async loadPredicateDefinitions() {
            this.predicateDefinitions = await TriggerRepository.getAllPredicateDefinitionsForPageView();
        },
        addAtomicPredicate() {
            const predicate = { ...this.value };
            predicate.children.push(new AtomicPredicate());
            this.$emit('input', predicate);
        },
        updatePredicate(changedPredicate, index) {
            const predicate = { ...this.value };
            predicate.children[index] = changedPredicate;
            this.$emit('input', predicate);
        },
    },
};
</script>
