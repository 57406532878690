import { ContainerPixels, ContainerPlaceholder } from '@/views';
import TriggerForm from '@/views/trigger/TriggerForm.vue';
import ContainerCreator from '@/views/ContainerCreator.vue';
import ContainerTag from '@/views/ContainerTag.vue';
import ContainerTriggers from '@/views/ContainerTriggers/ContainerTriggers.vue';
import PixelForm from '@/views/PixelForm/PixelForm.vue';
import ModalPageLayout from '@/views/layouts/ModalPageLayout.vue';
import AssignTrigger from '@/views/AssignTrigger.vue';
import ContainerSettings from '@/views/settings/ContainerSettings.vue';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default [
    {
        name: ROUTE_NAMES.CONTAINER_PLACEHOLDER,
        path: '/',
        component: ContainerPlaceholder,
    },
    {
        path: '/',
        component: ModalPageLayout,
        props: { default: true, persistent: true },
        children: [
            {
                name: ROUTE_NAMES.CONTAINER_CREATE,
                path: '/container/create',
                component: ContainerCreator,
            },
            {
                name: ROUTE_NAMES.CONTAINER_TAG,
                path: '/container/create/:id/tag',
                component: ContainerTag,
            },
        ],
    },
    {
        name: ROUTE_NAMES.CONTAINER,
        path: '/container/:containerId',
        component: ContainerPixels,
        props: true,
        children: [
            {
                path: '',
                component: ModalPageLayout,
                children: [
                    {
                        name: ROUTE_NAMES.PIXEL_CREATE,
                        path: 'pixel/create',
                        component: PixelForm,
                        meta: { isModal: true },
                    },
                    {
                        name: ROUTE_NAMES.PIXEL_UPDATE,
                        path: 'pixel/:pixelId',
                        component: PixelForm,
                        meta: { isModal: true },
                        props({ params }) {
                            return {
                                pixelId: parseInt(params.pixelId, 10),
                                containerId: params.containerId,
                            };
                        },
                    },
                    {
                        name: ROUTE_NAMES.TRIGGER_ASSIGN,
                        path: 'pixel/:pixelId/triggers',
                        component: AssignTrigger,
                        meta: { isModal: true },
                        props: true,
                    },
                    {
                        name: ROUTE_NAMES.TRIGGER_CREATE_ASSIGN,
                        path: 'pixel/:pixelId/trigger/create',
                        component: TriggerForm,
                        meta: { isModal: true },
                        props: true,
                    },
                    {
                        name: ROUTE_NAMES.CONTAINER_SETTINGS,
                        path: 'settings',
                        component: ContainerSettings,
                        meta: { isModal: true },
                        props: true,
                    },
                ],
            },
        ],
    },
    {
        name: ROUTE_NAMES.CONTAINER_TRIGGERS,
        path: '/container/:containerId/triggers',
        component: ContainerTriggers,
        props: true,
        children: [
            {
                path: '',
                component: ModalPageLayout,
                children: [
                    {
                        name: ROUTE_NAMES.TRIGGER_CREATE,
                        path: 'trigger/create',
                        component: TriggerForm,
                        meta: { isModal: true },
                        props: true,
                    },
                    {
                        name: ROUTE_NAMES.TRIGGER_UPDATE,
                        path: 'trigger/:triggerId',
                        component: TriggerForm,
                        meta: { isModal: true },
                        props({ params }) {
                            return {
                                triggerId: parseInt(params.triggerId, 10),
                                containerId: params.containerId,
                            };
                        },
                    },
                ],
            },
        ],
    },
];
