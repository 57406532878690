<template>
    <div id="modal-page-container">
        <VDialog
            transition="translateX"
            attach="#modal-page-container"
            :value="show"
            :persistent="true"
            @click:outside="closingFromOutsideClick"
        >
            <router-view @close="closingFromEmitted" />
            <slot @close="closingFromEmitted" />
        </VDialog>
    </div>
</template>

<script>
export default {
    name: 'ModalPageLayout',
    props: {
        show: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            emittedCloseNavigationName: null,
        };
    },
    computed: {
        currentRouteParams() {
            return this.$route.params;
        },
        parentRouteName() {
            return this.$route.matched[0].name;
        },
        closeNavigationName() {
            return this.emittedCloseNavigationName || this.parentRouteName;
        },
    },
    methods: {
        closingFromOutsideClick() {
            this.close();
        },
        closingFromEmitted(routeName) {
            this.emittedCloseNavigationName = routeName;
            this.close();
        },
        close() {
            this.$router.push({
                name: this.closeNavigationName,
                params: this.currentRouteParams,
            });
        },
    },
};
</script>
