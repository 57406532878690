import { Expose } from 'class-transformer';
import 'reflect-metadata';

export default class PixelServingType {
    @Expose()
    id: number;

    @Expose()
    name: string;

    key: string;
}
