<template>
    <PageLayout>
        <template #header-content>
            <div class="close-icon-wrapper" @click="close">
                <SrIcon class="pa-1" icon="close" size="xs" />
            </div>
        </template>
        <template #header-loading>
            <VProgressLinear v-if="isSaving || currentContainerLoading" :indeterminate="true" :value="0" color="blue" />
        </template>
        <template #footer-content>
            <ErrorBoxComponent v-model="error" outlined dismissible @close="resetError" />
            <SrButton type="secondary" class="ml-auto page-layout__submit-button" :disabled="isSaving" @click="save">
                Save
            </SrButton>
        </template>
        <VCol cols="12" class="pb-0 pt-1">
            <SrHeadline weight="bold"> Container Settings</SrHeadline>
        </VCol>
        <VCol v-if="currentContainer.id" cols="12" class="pt-0 container-settings">
            <VRow>
                <VCol cols="3">
                    <SrInputBox active> Container Settings</SrInputBox>
                </VCol>
                <VCol cols="9">
                    <VCard outlined class="settings-content">
                        <VContainer class="pt-1">
                            <VRow class="mt-3 d-flex justify-center">
                                <VCol cols="9">
                                    <VCol class="pt-0">
                                        <VRow>
                                            <VCol cols="3">
                                                <SrCheckbox
                                                    v-model="hasDisabledGrowthAnalyticsId"
                                                    class="disable-growth-analytics-id"
                                                    label="Disabled"
                                                />
                                            </VCol>
                                            <VCol>
                                                <SrInput
                                                    v-model="currentContainer.growthAnalyticsId"
                                                    label="Growth Analytics Frontend Id"
                                                    class="growth-analytics-id"
                                                    placeholder="Growth Analytics Frontend Id"
                                                    show-is-valid-indicator
                                                    :disabled="hasDisabledGrowthAnalyticsId"
                                                />
                                            </VCol>
                                        </VRow>
                                        <VRow>
                                            <VCol cols="3">
                                                <SrCheckbox
                                                    v-model="hasDisabledFacebookPixelId"
                                                    class="disable-facebook-pixel-id"
                                                    label="Disabled"
                                                />
                                            </VCol>
                                            <VCol>
                                                <SrInput
                                                    v-model="currentContainer.facebookPixelId"
                                                    class="facebook-pixel-id"
                                                    label="Facebook Pixel Id"
                                                    placeholder="Facebook Pixel Id"
                                                    show-is-valid-indicator
                                                    :disabled="hasDisabledFacebookPixelId"
                                                />
                                            </VCol>
                                        </VRow>
                                        <VRow>
                                            <VCol cols="3">
                                                <SrCheckbox
                                                    v-model="hasDisabledSnapchatPixelId"
                                                    class="disable-snapchat-pixel-id"
                                                    label="Disabled"
                                                />
                                            </VCol>
                                            <VCol>
                                                <SrInput
                                                    v-model="currentContainer.snapchatPixelId"
                                                    class="snapchat-pixel-id"
                                                    label="Snapchat Pixel Id"
                                                    placeholder="Snapchat Pixel Id"
                                                    show-is-valid-indicator
                                                    :disabled="hasDisabledSnapchatPixelId"
                                                />
                                            </VCol>
                                        </VRow>
                                        <VRow>
                                            <VCol cols="3">
                                                <SrCheckbox
                                                    v-model="hasDisabledTwitterPixelId"
                                                    class="disable-twitter-pixel-id"
                                                    label="Disabled"
                                                />
                                            </VCol>
                                            <VCol>
                                                <SrInput
                                                    v-model="currentContainer.twitterPixelId"
                                                    class="twitter-pixel-id"
                                                    label="Twitter Pixel Id"
                                                    placeholder="Twitter Pixel Id"
                                                    show-is-valid-indicator
                                                    :disabled="hasDisabledTwitterPixelId"
                                                />
                                            </VCol>
                                        </VRow>
                                        <VRow>
                                            <VCol>
                                                <SrInput
                                                    v-model="currentContainer.f8OperatorPublicAccessKey"
                                                    label="Fresh 8 Operator Public Access Key"
                                                    placeholder="Fresh 8 Operator Public Access Key"
                                                    show-is-valid-indicator
                                                />
                                            </VCol>
                                        </VRow>
                                        <VRow v-if="currentContainer.f8OperatorPublicAccessKey">
                                            <VCol>
                                                <ContainerSettingsFilter
                                                    v-model="sportFilter"
                                                    description="Sports Event Id"
                                                    class="sport-filter"
                                                />
                                            </VCol>
                                        </VRow>
                                        <VRow v-if="currentContainer.f8OperatorPublicAccessKey">
                                            <VCol>
                                                <ContainerSettingsFilter
                                                    v-model="casinoFilter"
                                                    description="Casino Game Id"
                                                    class="casino-filter"
                                                />
                                            </VCol>
                                        </VRow>
                                    </VCol>
                                </VCol>
                            </VRow>
                        </VContainer>
                    </VCard>
                </VCol>
            </VRow>
        </VCol>
    </PageLayout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout';
import { SrButton, SrHeadline, SrIcon, SrInput, SrInputBox, SrCheckbox } from '@ads/design-system';
import ContainerSettingsFilter from '@/views/settings/ContainerSettingsFilter';
import { emptySocialFilter } from '@/views/settings/types';
import { mapGetters } from 'vuex';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';

export default {
    name: 'ContainerSettings',
    components: {
        ErrorBoxComponent,
        PageLayout,
        SrInputBox,
        SrIcon,
        SrButton,
        SrHeadline,
        SrInput,
        ContainerSettingsFilter,
        SrCheckbox,
    },
    mixins: [ErrorBoxMixin],
    props: {
        containerId: {
            type: [Number, String],
            default: null,
        },
    },
    data() {
        return {
            isSaving: false,
        };
    },
    computed: {
        ...mapGetters(['currentContainer', 'currentContainerLoading']),
        hasDisabledFacebookPixelId: {
            get() {
                return !this.currentContainer.hasEnabledFacebookPixelId;
            },
            set(isDisabled) {
                this.currentContainer.hasEnabledFacebookPixelId = !isDisabled;
            },
        },
        hasDisabledSnapchatPixelId: {
            get() {
                return !this.currentContainer.hasEnabledSnapchatPixelId;
            },
            set(isDisabled) {
                this.currentContainer.hasEnabledSnapchatPixelId = !isDisabled;
            },
        },
        hasDisabledTwitterPixelId: {
            get() {
                return !this.currentContainer.hasEnabledTwitterPixelId;
            },
            set(isDisabled) {
                this.currentContainer.hasEnabledTwitterPixelId = !isDisabled;
            },
        },
        hasDisabledGrowthAnalyticsId: {
            get() {
                return !this.currentContainer.hasEnabledGrowthAnalyticsId;
            },
            set(isDisabled) {
                this.currentContainer.hasEnabledGrowthAnalyticsId = !isDisabled;
            },
        },
        sportFilter: {
            get() {
                if (!this.currentContainer.sportFilter) {
                    return { ...emptySocialFilter };
                }

                return this.currentContainer.sportFilter;
            },
            set(sportFilter) {
                this.currentContainer.sportFilter = sportFilter;
            },
        },
        casinoFilter: {
            get() {
                if (!this.currentContainer.casinoFilter) {
                    return { ...emptySocialFilter };
                }

                return this.currentContainer.casinoFilter;
            },
            set(casinoFilter) {
                this.currentContainer.casinoFilter = casinoFilter;
            },
        },
    },
    methods: {
        async save() {
            if (this.isSaving) {
                this.setError('Save is not allowed!');
                return;
            }

            this.isSaving = true;
            const containerId = this.currentContainer.id;
            const container = this.currentContainer;
            this.$store
                .dispatch('saveContainer', { container, containerId })
                .then(async () => {
                    await this.$store.dispatch('fetchContainerById', { id: this.containerId });
                    this.close();
                })
                .catch((error) => this.setError(error))
                .finally(() => {
                    this.isSaving = false;
                });
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.settings-content {
    min-height: 400px !important;
}
</style>
