<template>
    <div class="trigger-type-item outlined mb-2 d-flex pa-6" @click="emitInput">
        <div class="icon d-flex align-center pr-6">
            <SrIcon :icon="icon" />
        </div>
        <div class="type-info">
            <div class="label">
                <SrBodyText>
                    {{ label }}
                </SrBodyText>

                <p class="description mb-0 mt-1 caption gray-border--text">
                    {{ description }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import './ListSelectorItem.scss';

import { SrBodyText, SrIcon } from '@ads/design-system';

export default {
    name: 'ListSelectorItem',
    components: {
        SrBodyText,
        SrIcon,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
    },
    methods: {
        emitInput() {
            this.$emit('input', this.type);
        },
    },
};
</script>
