<template>
    <div class="trigger-type-selector pl-12 pr-12" :class="{ active: active }">
        <div class="content pl-8 pr-8 pt-4" :class="{ active: active }">
            <SrHeadline v-if="heading" level="3" class="pt-4 pb-4">
                {{ heading }}
            </SrHeadline>

            <template v-for="item in items">
                <div :key="item.type">
                    <ListSelectorItem v-model="internalValue" v-bind="item" />
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import './ListSelector.scss';
import { SrHeadline } from '@ads/design-system';
import ListSelectorItem from '@/views/ListSelector/ListSelectorItem';

export default {
    name: 'ListSelector',
    components: {
        ListSelectorItem,
        SrHeadline,
    },
    props: {
        heading: {
            type: String,
            default: '',
        },
        active: {
            type: Boolean,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            internalValue: null,
        };
    },
    watch: {
        internalValue(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>
