import AtomicPredicate from '@/views/trigger/TriggerPredicateConfiguration/AtomicPredicate';

export default class ComplexPredicate {
    type: string;

    operand: string;

    children: AtomicPredicate[];

    constructor() {
        this.type = 'complex';
        this.operand = 'AND';
        this.children = [];
    }
}
