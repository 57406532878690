interface IHeaderOption {
    text: string;
    value: string;
    align: string;
}

const headers: IHeaderOption[] = [
    {
        text: 'Trigger Name',
        value: 'name',
        align: 'left',
    },
    {
        text: 'Event Type',
        value: 'type',
        align: 'left',
    },
    {
        text: 'Config',
        value: 'config',
        align: 'left',
    },
    {
        text: 'Assigned Pixels',
        value: 'pixels',
        align: 'center',
    },
    {
        text: 'Last Edited',
        value: 'updatedAt',
        align: 'right',
    },
    {
        text: 'Actions',
        value: 'options',
        align: 'right',
    },
];
export default class TriggerTableViewModel {
    getHeaders(isAssignable = false): IHeaderOption[] {
        if (isAssignable) {
            return [
                {
                    text: 'Assign',
                    value: 'assign',
                    align: 'left',
                },
                ...headers,
            ];
        }

        return [
            {
                text: 'ID',
                value: 'id',
                align: 'left',
            },
            ...headers,
        ];
    }
}
