// todo CAPS constant names
import pixelFormConfiguration from './pixelFormConfiguration';

const footerItems = [{ title: 'Imprint' }, { title: 'Privacy Notice' }, { title: 'Cookie Notice' }, { title: 'FAQ' }];

const copyText = {
    en: {
        containerPlaceholder: {
            title: 'No Containers Available!',
            text:
                'To start using our Pixel Manager you will need to first create a container and then install our ' +
                'Pixel Manager on your website. It will start tracking page ' +
                'view events right after the code is installed on the website. ',
        },
    },
};

const API_URL = process.env.VUE_APP_API_URL || 'https://386e6vki32.execute-api.eu-west-1.amazonaws.com/dev';
const IAM_API_URL = process.env.VUE_APP_IAM_API_URL || 'https://pf9oud1dzd.execute-api.eu-west-1.amazonaws.com/dev';
export { pixelFormConfiguration, copyText, API_URL, IAM_API_URL, footerItems };
