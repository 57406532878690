import Vue from 'vue';

export default {
    setLoadingState(state, { key, isLoading }) {
        state.loadingStates[key] = isLoading;
    },
    toggleSidebar(state) {
        state.isSidebarVisible = !state.isSidebarVisible;
    },
    selectedContainer(state, selectedContainer) {
        state.selectedContainer = selectedContainer;
    },
    setCurrentContainer(state, currentContainer) {
        state.currentContainer = currentContainer;
    },
    setCurrentContainerError(state, error) {
        state.currentContainerError = error?.response?.message || JSON.stringify(error) || null;
    },
    setBusinessEntities(state, businessEntities) {
        Vue.set(state, 'businessEntities', businessEntities);
    },
    setCurrentContainerTag(state, currentContainerTag) {
        Vue.set(state, 'currentContainerTag', currentContainerTag);
    },
    setContainers(state, containers) {
        Vue.set(state, 'containers', containers);
    },
    addContainer(state, container) {
        state.containers.push(container);
    },
    setTriggers(state, triggers) {
        state.triggers = triggers;
    },
    setPixels(state, pixels) {
        Vue.set(state, 'pixels', pixels);
    },
    setPixelConversionTypes(state, pixelConversionTypes) {
        Vue.set(state, 'pixelConversionTypes', pixelConversionTypes);
    },
    setPixelServingTypes(state, pixelServingTypes) {
        Vue.set(state, 'pixelServingTypes', pixelServingTypes);
    },
    setVideoEventTypes(state, videoEventTypes) {
        Vue.set(state, 'videoEventTypes', videoEventTypes);
    },
    setVariables(state, variables) {
        Vue.set(state, 'variables', variables);
    },
};
