<template>
    <VRow style="width: 500px">
        <VCol>
            <VRow>
                <VCol>
                    <h3 class="mb-8 pixel-form__action-title">Options</h3>
                    <VRow>
                        <VCol cols="6">
                            <SrInput
                                v-model="config.timerIdentifier"
                                label="Timer Identifier"
                                :placeholder="config.timerIdentifier"
                                :disabled="true"
                                :required="true"
                            />
                        </VCol>
                        <VCol cols="6">
                            <SrInput
                                v-model="config.timestampIdentifier"
                                label="Timestamp Identifier"
                                :placeholder="config.timestampIdentifier"
                                :disabled="true"
                                :required="true"
                            />
                        </VCol>
                    </VRow>
                    <VRow>
                        <VCol cols="6">
                            <SrInput
                                v-model="config.secondsToTrigger"
                                label="Seconds To Trigger"
                                :placeholder="String(defaultConfig.secondsToTrigger)"
                                :required="true"
                                type="number"
                            />
                        </VCol>
                        <VCol cols="6">
                            <SrInput
                                v-model="config.ageLimitInSeconds"
                                label="Age Limit"
                                :placeholder="String(defaultConfig.ageLimitInSeconds)"
                                :required="true"
                                type="number"
                            />
                        </VCol>
                    </VRow>
                    <VRow>
                        <VCol cols="6">
                            <SrInput
                                v-model="config.intervalTimeout"
                                label="Timeout Interval"
                                :placeholder="String(defaultConfig.intervalTimeout)"
                                :required="true"
                                type="number"
                            />
                        </VCol>
                    </VRow>
                </VCol>
            </VRow>
        </VCol>
    </VRow>
</template>

<script>
import AbstractTriggerTypeConfig from '@/views/trigger/TriggerTypeConfig/AbstractTriggerTypeConfig';
import { SrInput } from '@ads/design-system';

export default {
    name: 'DomainBasedTimer',
    components: { SrInput },
    extends: AbstractTriggerTypeConfig,
    created() {
        const date = new Date();
        this.defaultConfig = {
            timerIdentifier: `timerIdentifier_${date.getTime()}`,
            timestampIdentifier: `timestampIdentifier_${date.getTime()}`,
            secondsToTrigger: 5,
            ageLimitInSeconds: 30,
            intervalTimeout: 500,
        };
        this.config = this.value && Object.keys(this.value).length ? this.value : { ...this.defaultConfig };
    },
};
</script>
