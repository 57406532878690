<template>
    <div class="side-navigation">
        <SrNavigationBar :items="navItems" />
    </div>
</template>

<script>
import { SrNavigationBar } from '@ads/design-system';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'SideNavigation',
    components: { SrNavigationBar },
    data() {
        return {
            navItems: [
                {
                    title: 'Pixels',
                    icon: 'pixelmanager',
                    routeName: ROUTE_NAMES.CONTAINER,
                    domainName: 'pixel-manager',
                    resourceType: 'pixel',
                },
                {
                    title: 'Triggers',
                    icon: 'triggers',
                    routeName: ROUTE_NAMES.CONTAINER_TRIGGERS,
                    domainName: 'pixel-manager',
                    resourceType: 'trigger',
                },
            ],
        };
    },
};
</script>

<style scoped>
.pm-sidebar {
    background-color: transparent !important;
    z-index: 1;
}
</style>
