import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import { plainToClass } from 'class-transformer';
import Trigger from '@/models/Trigger';

class TriggerRepository {
    private requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async saveWithContainerId(trigger, containerId: string): Promise<unknown> {
        const baseUrl = `${API_URL}/containers/${containerId}/triggers`;

        const isNewTrigger = trigger.id === null;

        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'trigger',
            action: isNewTrigger ? ACTION_NAME.CREATE : ACTION_NAME.EDIT,
        };

        if (isNewTrigger) {
            return this.requestService.post(baseUrl, trigger, options);
        }

        return this.requestService.put(`${baseUrl}/${trigger.id}`, trigger, options);
    }

    async getByTriggerIdAndContainerId(triggerId: number, containerId: string): Promise<Trigger> {
        const url = `${API_URL}/containers/${containerId}/triggers/${triggerId}`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'trigger',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get(url, options);
    }

    async deleteByTriggerIdAndContainerId(triggerId: number, containerId: string): Promise<unknown> {
        const url = `${API_URL}/containers/${containerId}/triggers/${triggerId}`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'trigger',
            action: ACTION_NAME.REMOVE,
        };

        return this.requestService.delete(url, options).then(() => ({ status: true }));
    }

    async getAllByContainerId(containerId: string): Promise<Trigger[]> {
        const url = `${API_URL}/containers/${containerId}/triggers`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'trigger',
            action: ACTION_NAME.READ,
        };

        const data = await this.requestService.get(url, options);

        return plainToClass(Trigger, data as unknown as []);
    }

    async getAllFilterCriteriaForPageView() {
        return [
            {
                value: 1,
                label: 'Page URL',
            },
            {
                value: 2,
                label: 'Referrer',
            },
            {
                value: 3,
                label: 'Custom Variable',
            },
        ];
    }

    async getAllPredicateDefinitionsForPageView() {
        return [
            {
                value: 1,
                label: 'equals',
            },
            {
                value: 2,
                label: 'contains',
            },
            {
                value: 3,
                label: 'matches RegEx',
            },
            {
                value: 4,
                label: 'not equals',
            },
            {
                value: 5,
                label: 'not contains',
            },
            {
                value: 6,
                label: 'does not match RegEx',
            },
            {
                value: 7,
                label: 'less than',
            },
            {
                value: 8,
                label: 'grater than or equal to',
            },
        ];
    }
}

export default new TriggerRepository(new RequestService());
