const ROUTE_NAMES = {
    CONTAINER: 'container',
    CONTAINER_TAG: 'container-tag',
    CONTAINER_CREATE: 'create-container',
    CONTAINER_PLACEHOLDER: 'container-placeholder',
    CONTAINER_SETTINGS: 'container-settings',
    CONTAINER_TRIGGERS: 'container-triggers',
    PIXEL_CREATE: 'create-pixel',
    PIXEL_UPDATE: 'update-pixel',
    TRIGGER_ASSIGN: 'assign-trigger',
    TRIGGER_CREATE: 'create-trigger',
    TRIGGER_CREATE_ASSIGN: 'create-assign-trigger',
    TRIGGER_UPDATE: 'update-trigger',
} as const;

export { ROUTE_NAMES };
