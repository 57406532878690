<template>
    <VRow class="d-flex">
        <VCol class="flex-row-reverse text-right">
            <router-link :to="{ name: createContainerRouteName }">
                <SrButton color="blue" text> Add Container </SrButton>
            </router-link>
        </VCol>
        <VCol style="flex: 0 1 400px">
            <SrSelect
                v-model="selectedContainer"
                :disabled="isLoading || currentContainerLoading"
                :loading="isLoading || currentContainerLoading"
                label="Container"
                searchable
                return-object
                :items="items"
                dense
                mandatory
                attach
            />
        </VCol>
    </VRow>
</template>

<script>
import { SrSelect, SrButton } from '@ads/design-system';
import { mapGetters } from 'vuex';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'ContainerSelector',
    components: { SrSelect, SrButton },
    data() {
        return {
            selectedContainer: null,
            isLoading: false,
            createContainerRouteName: ROUTE_NAMES.CONTAINER_CREATE,
        };
    },
    computed: {
        ...mapGetters(['currentContainer', 'currentContainerLoading']),
        items() {
            return this.$store.getters.containers.map((content) => ({ label: content.name, value: content.id }));
        },
    },
    watch: {
        selectedContainer: 'changeContainer',
        currentContainer: 'setContainer',
    },
    created() {
        this.loadContainersIfNeeded();
        if (!this.selectedContainer && this.currentContainer.id) {
            this.selectedContainer = {
                label: this.currentContainer.name,
                value: this.currentContainer.id,
            };
        }
    },
    methods: {
        setContainer(container) {
            if (!this.selectedContainer || container.id !== this.selectedContainer.value) {
                this.selectedContainer = { label: container.name, value: container.id };
            }
        },
        async loadContainersIfNeeded() {
            if (this.items.length === 0) {
                this.isLoading = true;
                await this.$store.dispatch('getAllContainers').catch(() => null);
                this.isLoading = false;
            }
        },
        changeContainer(container) {
            this.$store.commit('selectedContainer', {
                id: container.value,
                name: container.label,
            });
            this.$store.commit('setCurrentContainerError', null);
            if (this.$router.currentRoute.params.containerId !== container.value) {
                this.$router.push({
                    name: ROUTE_NAMES.CONTAINER,
                    params: { containerId: container.value },
                });
            }
        },
    },
};
</script>
