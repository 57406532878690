import { API_URL } from '@/config';
import Pixel from '@/models/Pixel';
import { plainToClass } from 'class-transformer';
import { ACTION_NAME, IRequestOptions, RequestService, RequestService as RequestServiceType } from '@ads/iam-library';
import PixelConversionType from '@/models/PixelConversionType';
import PixelServingType from '@/models/PixelServingType';
import VideoEventType from '@/models/VideoEventType';

class PixelRepository {
    private requestService: RequestServiceType;

    private readonly domainName: 'pixel-manager';

    private readonly resourceName: 'pixel';

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getPixelsForContainer(containerId): Promise<Pixel[]> {
        const url = `${API_URL}/containers/${containerId}/pixels`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.READ,
        };

        const data = await this.requestService.get(url, options);

        return plainToClass(Pixel, data as unknown as []);
    }

    async getPixelConversionTypes(): Promise<PixelConversionType[]> {
        const url = `${API_URL}/pixel-conversion-types`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.READ,
        };

        const data = await this.requestService.get(url, options);

        return plainToClass(PixelConversionType, data as unknown as []);
    }

    async assignTrigger(pixelId, triggerId, containerId): Promise<any> {
        const url = `${API_URL}/pixels/${pixelId}/assignedTriggers`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.EDIT,
        };

        return this.requestService.post(url, { triggerId, containerId }, options);
    }

    async unassignTrigger(pixelId, triggerId, containerId): Promise<any> {
        const url = `${API_URL}/pixels/${pixelId}/assignedTriggers/${triggerId}?containerId=${containerId}`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.EDIT,
        };

        return this.requestService.delete(url, options);
    }

    async save(pixel, containerId: string): Promise<any> {
        const baseUrl = `${API_URL}/containers/${containerId}/pixels`;
        const isNewPixel = pixel.id === null;

        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: isNewPixel ? ACTION_NAME.CREATE : ACTION_NAME.EDIT,
        };

        if (isNewPixel) {
            return this.requestService.post(baseUrl, pixel, options);
        }

        return this.requestService.put(`${baseUrl}/${pixel.id}`, pixel, options);
    }

    async delete(id: number, containerId: string): Promise<void> {
        const url = `${API_URL}/containers/${containerId}/pixels/${id}`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.REMOVE,
        };

        return this.requestService.delete(url, options);
    }

    async getByPixelIdAndContainerId(pixelId: number, containerId: string): Promise<Pixel> {
        const url = `${API_URL}/containers/${containerId}/pixels/${pixelId}`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.READ,
        };

        return this.requestService.get<Pixel>(url, options);
    }

    async getPixelServingTypes(): Promise<PixelServingType[]> {
        const url = `${API_URL}/pixel-serving-types`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.READ,
        };

        const pixelServingTypes = (await this.requestService.get(url, options)) as unknown as PixelServingType[];

        return plainToClass(PixelServingType, pixelServingTypes);
    }

    async getVideoEventTypes(): Promise<VideoEventType[]> {
        const url = `${API_URL}/video-event-type`;
        const options: IRequestOptions = {
            domain: this.domainName,
            resource: this.resourceName,
            action: ACTION_NAME.READ,
        };

        const videoEventTypes = (await this.requestService.get(url, options)) as unknown as VideoEventType[];

        return plainToClass(VideoEventType, videoEventTypes);
    }

    async bulkCreateServingPixels(containerId: string, businessEntityId: number): Promise<void> {
        const baseUrl = `${API_URL}/containers/${containerId}/pixels/bulk/serving`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'pixel',
            action: ACTION_NAME.CREATE,
        };

        return this.requestService.post(baseUrl, { businessEntityId }, options);
    }

    async bulkCreateVideoPixels(containerId: string, businessEntityId: number): Promise<void> {
        const baseUrl = `${API_URL}/containers/${containerId}/pixels/bulk/video`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'pixel',
            action: ACTION_NAME.CREATE,
        };

        return this.requestService.post(baseUrl, { businessEntityId }, options);
    }
}

export default new PixelRepository(new RequestService());
