<template>
    <PageLayout class="container-tag">
        <template #header-content>
            <div class="close-icon-wrapper" @click="navigateToContainer">
                <SrIcon class="pa-1" icon="close" size="xs" />
            </div>
        </template>
        <template #header-loading>
            <VProgressLinear v-if="isLoading" :indeterminate="true" :value="0" color="blue" />
        </template>
        <template #footer-content>
            <ErrorBoxComponent v-model="error" outlined dismissible @close="resetError" />
            <SrButton type="secondary" class="ml-auto page-layout__submit-button" @click="navigateToContainer">
                Continue
            </SrButton>
        </template>
        <VCol>
            <div class="d-flex align-center mb-4">
                <SrAppContainerHeader class="pa-0 ml-2"> Install Sportradar Pixel Manager on your site </SrAppContainerHeader>
            </div>
        </VCol>

        <VCol class="pt-0" cols="12 ">
            <p class="mb-8 mt-0 body-2">
                Copy and paste the Sportradar code below as high in the <span>&lt;head> </span>of every page of your website as
                possible:
            </p>
            <code id="container-tag" class="pa-4 pb-0 pt-0">{{ containerTag }}</code>
            <div class="d-flex justify-end">
                <SrButton class="blue--text blue-hover" depressed @click="copyTagToBuffer">Copy to Clipboard </SrButton>
            </div>
        </VCol>

        <VCol class="mt-4" cols="6">
            <p>
                For more information about installing the Sportradar Pixel Manager,
                <a href="/" class="font-weight-bold"> Visit Our Starter Guide.</a>
                <span> You may return to this page at a later date to install container.</span>
            </p>
        </VCol>
    </PageLayout>
</template>

<script>
import { SrAppContainerHeader, SrButton, SrIcon } from '@ads/design-system';
import PageLayout from '@/views/layouts/PageLayout';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'ContainerTag',
    components: { ErrorBoxComponent, PageLayout, SrIcon, SrButton, SrAppContainerHeader },
    mixins: [ErrorBoxMixin],
    data() {
        return {
            containerId: this.$route.params.id,
            isLoading: false,
        };
    },
    computed: {
        containerTag() {
            return this.$store.getters.currentContainerTag;
        },
    },
    async created() {
        this.isLoading = true;
        await this.$store.dispatch('getContainerTag', { id: this.containerId }).catch((error) => this.setError(error));
        this.isLoading = false;
    },
    methods: {
        copyTagToBuffer() {
            navigator.clipboard.writeText(this.containerTag);
        },
        navigateToContainer() {
            this.$router.push({
                name: ROUTE_NAMES.CONTAINER,
                params: { containerId: this.containerId },
            });
        },
    },
};
</script>
