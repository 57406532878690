<template>
    <div>
        <SrBodyText v-if="description" color="dark-grey--text">
            {{ description }}
        </SrBodyText>
        <div class="d-flex flex-wrap mt-10">
            <SrSelect
                v-model="selectedFilterTarget"
                class="mr-3 predicate-input"
                :items="filterTarget"
                label="From Page URL"
                hide-details
            />
            <SrSelect
                v-model="selectedFilterType"
                class="mr-3 predicate-input"
                :items="filterTypes"
                label="matches RegEx"
                hide-details
                attach
            />
            <SrInput v-model="filter.filterValue" hide-details class="filter-input" placeholder="Regex" label="Regex" />
        </div>
    </div>
</template>

<script>
import { SrBodyText, SrInput, SrSelect } from '@ads/design-system';
import IContainerFilter from '@/models/IContainerFilter';

export default {
    name: 'ContainerSettingsFilter',
    components: {
        SrBodyText,
        SrSelect,
        SrInput,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: IContainerFilter,
            required: true,
        },
        description: {
            type: String,
        },
    },
    data() {
        return {
            filter: this.value,
            filterTarget: [{ label: 'From Page URL', value: 'url' }],
            filterTypes: [{ label: 'matches RegEx', value: 'pattern' }],
        };
    },
    computed: {
        selectedFilterTarget: {
            get() {
                const filterTarget = this.filterTarget.find((target) => target.value === this.filter.filterTarget);
                if (!filterTarget) {
                    return this.filterTarget[0];
                }

                return filterTarget;
            },
            set(filterTarget) {
                this.filter.filterTarget = filterTarget.value;
            },
        },
        selectedFilterType: {
            get() {
                const filterType = this.filterTypes.find((type) => type.value === this.filter.filterType);
                if (!filterType) {
                    return this.filterTypes[0];
                }

                return filterType;
            },
            set(filterType) {
                this.filter.filterFilterType = filterType.value;
            },
        },
    },
    watch: {
        value: {
            deep: true,
            handler(filter) {
                this.filter = filter;
            },
        },
        filter: {
            deep: true,
            handler(filter) {
                if (!filter.filterValue) {
                    this.$emit('input', null);
                } else {
                    filter.filterTarget = this.selectedFilterTarget.value;
                    filter.filterType = this.selectedFilterType.value;
                    this.$emit('input', filter);
                }
            },
        },
    },
};
</script>
