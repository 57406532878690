import { Expose, Type, Exclude, Transform } from 'class-transformer';
import 'reflect-metadata';
import Trigger from '@/models/Trigger';
import PixelConversionType from '@/models/PixelConversionType';
import PixelServingType from '@/models/PixelServingType';
import { PIXEL_TYPE, PIXEL_TAG_TYPE } from '@/views/PixelForm/PixelCodeLoader';
import Container from '@/models/Container';
import VideoEventType from '@/models/VideoEventType';

export default class Pixel {
    @Expose()
    id: number;

    @Expose()
    name: string;

    @Expose()
    @Type(() => Number)
    pixelConversionTypeId: number;

    @Expose()
    twitterEventId: string;

    @Expose()
    @Type(() => Boolean)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    trackGrowthAnalytics: boolean;

    @Exclude({ toClassOnly: true })
    @Type(() => PixelConversionType)
    pixelConversionType: PixelConversionType;

    @Type(() => VideoEventType)
    @Transform(({ obj }) => obj.videoEventType)
    videoEventType: VideoEventType;

    @Exclude({ toClassOnly: true })
    @Type(() => PixelServingType)
    pixelServingType: PixelServingType;

    @Expose()
    @Type(() => Number)
    postViewDays: number;

    @Expose()
    @Type(() => Number)
    postClickDays: number;

    @Expose()
    @Type(() => Number)
    expirationDays: number;

    @Expose()
    @Type(() => Number)
    holdingId: number;

    @Expose()
    @Type(() => Number)
    businessEntityId: number;

    @Expose()
    @Type(() => Container)
    container: Container;

    @Expose()
    @Type(() => Trigger)
    triggers: Trigger[];

    @Expose()
    @Type(() => Boolean)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    eventDisabled: boolean;

    @Expose()
    @Type(() => Boolean)
    isServerToServer: boolean;

    @Expose()
    @Type(() => String)
    pixelType: PIXEL_TYPE;

    @Expose()
    @Type(() => String)
    tagType: PIXEL_TAG_TYPE;
}
