import { Expose } from 'class-transformer';
import 'reflect-metadata';

export default class BusinessEntity {
    @Expose()
    id: number;

    @Expose()
    dspId: number;
}
