<template>
    <div
        :class="{
            'container-overview': true,
            'container-selection-missing': !selectedContainer.name,
            'has-error': hasContainerError,
        }"
    >
        <div class="container-overview-content">
            <div v-if="hasContainerError || error" class="container-header has-error">
                <ErrorBoxComponent :error="errorMessage" :dismissible="error" @close="resetError" />
            </div>

            <div v-show="!error && selectedContainer.name" class="container-header was-created">
                <div>
                    <div class="d-flex flex-column justify-center headline-container">
                        <SrHeadline level="4" color="grey--text" class="ma-0" :title="subheadingText">
                            {{ subheadingText }}
                        </SrHeadline>
                        <SrHeadline level="3" class="ma-0">
                            {{ selectedContainer.name }}
                        </SrHeadline>
                    </div>
                </div>
                <div class="published-button-group">
                    <SrButton
                        v-if="selectedContainer.id && !currentContainerLoading"
                        text
                        color="primary"
                        :to="{ name: containerSettingsRouteName }"
                    >
                        <template #default>
                            <div>Settings</div>
                        </template>
                    </SrButton>
                    <SrButton :loading="isPublishLoading" class="white--text" color="secondary" @click="publishContainer">
                        Publish
                    </SrButton>
                </div>
            </div>
            <VDivider />
            <VProgressLinear v-if="currentContainerLoading || isPublishLoading" indeterminate :value="0" color="blue" />
        </div>
    </div>
</template>

<script>
import { SrButton, SrHeadline } from '@ads/design-system';
import { mapGetters } from 'vuex';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'ContainerHeader',
    components: {
        ErrorBoxComponent,
        SrButton,
        SrHeadline,
    },
    mixins: [ErrorBoxMixin],
    data() {
        return {
            containerSettingsRouteName: ROUTE_NAMES.CONTAINER_SETTINGS,
        };
    },
    computed: {
        ...mapGetters([
            'selectedContainer',
            'currentContainer',
            'currentContainerLoading',
            'isPublishLoading',
            'containerError',
            'hasContainerError',
        ]),
        errorMessage() {
            if (this.error) {
                return this.error;
            }
            return this.containerError;
        },
        subheadingText() {
            const publishedAt = this.currentContainer.publishedAt;
            if (publishedAt) {
                const date = new Date(publishedAt);
                const dateFormatted = date.toLocaleString(navigator.language);

                return `Container published on ${dateFormatted}`;
            }
            return 'Container';
        },
    },
    watch: {
        containerError: 'setContainerError',
    },
    created() {
        if (!this.selectedContainer.id) {
            this.setLoadingState('currentContainer', true);
        }
    },
    methods: {
        setContainerError(error) {
            if (error !== 'null') {
                this.setLoadingState('currentContainer', false);
            }
        },
        setErrorMessage(error) {
            this.setError(error);
            this.setLoadingState('currentContainer', false);
            this.setLoadingState('publishContainer', false);
        },
        async publishContainer() {
            if (!this.currentContainer.id) {
                return;
            }
            this.resetError();
            await this.$store.dispatch('publishContainer', this.currentContainer.id).catch((error) => {
                this.setErrorMessage(error);
            });
        },
        setLoadingState(key, isLoading) {
            this.$store.commit('setLoadingState', { key, isLoading });
        },
    },
};
</script>
