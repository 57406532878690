import { Expose, Type } from 'class-transformer';
import 'reflect-metadata';

export default class Variable {
    @Expose()
    id: number;

    @Expose()
    name: string;

    @Expose()
    type: VARIABLE_TYPE;

    @Expose()
    @Type(() => Date)
    createdAt: Date;

    @Expose()
    @Type(() => Date)
    updatedAt: Date;
}

export enum VARIABLE_TYPE {
    CUSTOM_JAVASCRIPT = 'CUSTOM_JAVASCRIPT',
    FIRST_PARTY_COOKIE = 'FIRST_PARTY_COOKIE',
    JAVASCRIPT_VARIABLE = 'JAVASCRIPT_VARIABLE',
    DOM_ELEMENT = 'DOM_ELEMENT',
    CONSTANT = 'CONSTANT',
}

export const labelByVariableType = {
    [VARIABLE_TYPE.CUSTOM_JAVASCRIPT]: 'Custom JavaScript',
    [VARIABLE_TYPE.FIRST_PARTY_COOKIE]: '1st Party Cookie',
    [VARIABLE_TYPE.JAVASCRIPT_VARIABLE]: 'JavaScript Variable',
    [VARIABLE_TYPE.DOM_ELEMENT]: 'DOM Element',
    [VARIABLE_TYPE.CONSTANT]: 'Constant',
};
