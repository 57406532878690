<template>
    <VCol class="pl-0 pr-0">
        <SrDataPlaceholder
            v-if="!isLoading && triggers.length === 0"
            class="outlined pa-12"
            icon="triggers"
            text="Create trigger to be able to fire pixels and get valuable insights."
            title="No Triggers Available!"
        >
            <SrButton type="secondary" class="mt-2" :to="{ name: createTriggerRouteName }"> Create </SrButton>
        </SrDataPlaceholder>
        <SrTable
            v-else
            :headers="viewModel.getHeaders(isAssignable)"
            :items="triggers"
            :loading="isLoading"
            class="trigger-table"
            show-footer
        >
            <template #assign="trigger">
                <span
                    class="blue--text clickable"
                    @click="triggerClicked(trigger)"
                    v-text="isTriggerAssigned(trigger) ? 'unassign' : 'assign'"
                />
            </template>
            <template #name="trigger">
                <router-link
                    v-if="!isAssignable"
                    :to="{ name: updateTriggerRouteName, params: { triggerId: trigger.id } }"
                    class="font-weight-bold"
                >
                    {{ trigger.name }}
                </router-link>
                <span v-else>{{ trigger.name }}</span>
            </template>
            <template #type="trigger">
                {{ triggerTypeLabels[trigger.type] }}
            </template>
            <template #config="trigger">
                <div :title="JSON.stringify(trigger.config)" class="col-ellipsis">
                    {{ JSON.stringify(trigger.config) }}
                </div>
            </template>
            <template #pixels="trigger">
                <router-link
                    v-for="(pixel, index) in trigger.pixels"
                    :key="pixel.id"
                    :to="{ name: updatePixelRouteName, params: { pixelId: pixel.id } }"
                    class="font-weight-bold"
                >
                    {{ pixel.name }}
                    <span v-if="index + 1 < trigger.pixels.length">, </span>
                </router-link>
            </template>
            <template #updatedAt="trigger">
                {{ trigger.updatedAt.getTime() | castTimestampToReadableDiff }}
            </template>
            <template #options="trigger">
                <TriggerTableActions :item="trigger" @delete="deleteSuccess" />
            </template>
        </SrTable>
    </VCol>
</template>

<script>
import { castTimestampToReadableDiff, SrTable, SrButton, SrDataPlaceholder } from '@ads/design-system';
import triggerTypeLabels from '@/views/trigger/triggerTypeLabels';
import '@/views/ContainerTriggers/ContainerTriggerTable.scss';
import TriggerTableViewModel from '@/views/TriggerTableViewModel';
import TriggerTableActions from '@/views/TriggerTableActions';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'TriggersTable',
    filters: { castTimestampToReadableDiff },
    components: { SrTable, TriggerTableActions, SrButton, SrDataPlaceholder },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        triggers: {
            type: Array,
            required: true,
        },
        isAssignable: {
            type: Boolean,
            default: false,
        },
        pixel: {
            type: Object,
        },
    },
    data() {
        return {
            triggerTypeLabels,
            containerId: this.$route.params.containerId,
            viewModel: new TriggerTableViewModel(),
            updatePixelRouteName: ROUTE_NAMES.PIXEL_UPDATE,
            createTriggerRouteName: ROUTE_NAMES.TRIGGER_CREATE,
            updateTriggerRouteName: ROUTE_NAMES.TRIGGER_UPDATE,
        };
    },
    methods: {
        isTriggerAssigned(trigger) {
            return this.$store.getters.isTriggerAssignedToPixel(trigger, this.pixel);
        },
        getUpdateTriggerRouteParams(trigger) {
            return {
                containerId: this.containerId,
                triggerId: trigger.id,
            };
        },
        triggerClicked(trigger) {
            this.$emit('trigger-clicked', trigger);
        },
        deleteSuccess() {
            this.$emit('trigger-deleted');
        },
    },
};
</script>
