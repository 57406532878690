import { API_URL } from '@/config';
import { plainToClass } from 'class-transformer';
import Variable from '@/models/Variable';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';

class VariableRepository {
    private requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async saveWithContainerId(variable, containerId: string): Promise<any> {
        const url = `${API_URL}/containers/${containerId}/variables`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'variable',
            action: ACTION_NAME.CREATE,
        };

        return this.requestService.post(url, variable, options);
    }

    async getAllByContainerId(containerId: string) {
        const url = `${API_URL}/containers/${containerId}/variables`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'variable',
            action: ACTION_NAME.READ,
        };

        const data = await this.requestService.get(url, options);

        return plainToClass(Variable, data as unknown as []);
    }
}

export default new VariableRepository(new RequestService());
