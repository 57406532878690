<template>
    <PageLayout>
        <template #header-content>
            <div class="close-icon-wrapper" @click="close">
                <SrIcon class="pa-1" icon="close" size="xs" />
            </div>
        </template>
        <template #header-loading>
            <VProgressLinear v-if="isSaving" :indeterminate="true" :value="0" color="blue" />
        </template>
        <template #footer-content>
            <ErrorBoxComponent v-model="error" outlined dismissible @close="resetError" />
            <SrButton
                type="secondary"
                class="ml-auto page-layout__submit-button"
                :loading="isSaving"
                :disabled="isSaving || !formIsValid"
                @click="saveContainer"
            >
                Create
            </SrButton>
        </template>
        <VRow class="mt-6">
            <VCol cols="12" class="pb-0 pt-1">
                <SrHeadline weight="bold"> Add Container </SrHeadline>
            </VCol>
            <VCol cols="12" class="pt-0">
                <VForm ref="form" v-model="formIsValid">
                    <VRow>
                        <VCol cols="4">
                            <h3 class="subtitle-1 brand-blue--text mb-7">Configuration</h3>
                            <SrInput
                                v-model="name"
                                label="Name"
                                hint="Please set a container name"
                                placeholder="Name"
                                class="mb-4"
                                required
                            />
                            <SrSelect
                                v-model="country"
                                :items="countries"
                                label="Country"
                                hint="Please select a container country"
                                clearable
                                required
                                attach
                            />
                            <SrSelect
                                v-model="holdingId"
                                label="Holdings"
                                :items="selectableHoldings"
                                searchable
                                :disabled="holdingLoading"
                                :loading="holdingLoading"
                                required
                                attach
                            />
                            <SrSelect
                                v-model="businessEntityId"
                                label="Business Entities"
                                :items="selectableBusinessEntities"
                                searchable
                                required
                                :loading="businessEntityLoading"
                                :disabled="!holdingId"
                                attach
                            />
                        </VCol>
                        <VCol cols="4" class="ml-10">
                            <h3 class="subtitle-1 brand-blue--text mb-7">Target Platform</h3>
                            <div class="mt-4 mb-6 d-flex justify-space-between platform-buttons-container">
                                <PlatformButton
                                    v-for="platform in platforms"
                                    :key="platform.name"
                                    :icon="platform.icon"
                                    :name="platform.name"
                                    :text="platform.text"
                                    class="flex-grow-0 mr-2"
                                    :active="isPlatformActive(platform.name)"
                                />
                            </div>
                        </VCol>
                    </VRow>
                </VForm>
            </VCol>
        </VRow>
    </PageLayout>
</template>

<script>
import { footerItems } from '@/config';
import { SrHeadline, SrButton, SrInput, SrSelect, SrIcon } from '@ads/design-system';
import PlatformButton from '@/views/PlatformButton';
import Container from '@/models/Container';
import PageLayout from '@/views/layouts/PageLayout';
import { plainToClass } from 'class-transformer';
import { mapGetters } from 'vuex';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'ContainerCreator',
    components: {
        ErrorBoxComponent,
        PageLayout,
        SrIcon,
        PlatformButton,
        SrButton,
        SrSelect,
        SrInput,
        SrHeadline,
    },
    mixins: [ErrorBoxMixin],
    data() {
        return {
            show: true,
            name: '',
            country: '',
            formIsValid: false,
            footerItems,
            platformName: '',
            countries: ['Austria', 'England', 'Nigeria', 'France'],
            platforms: [{ name: 'Web', icon: 'web', text: 'target desktop and mobile web pages' }],
            holdingId: null,
            businessEntityId: null,
            businessEntities: [],
            holdings: [],
            holdingLoading: false,
            businessEntityLoading: false,
            isSaving: false,
            error: null,
        };
    },
    computed: {
        ...mapGetters(['currentContainer']),
        selectableBusinessEntities() {
            const businessEntitiesWithDspId = this.businessEntities.filter(({ dspId }) => dspId !== null);

            return businessEntitiesWithDspId.map((businessEntity) => ({
                value: businessEntity.id,
                label: businessEntity.name,
            }));
        },
        selectableHoldings() {
            const holdings = this.holdings.filter(({ dspId }) => dspId !== null);
            return holdings.map((holding) => ({
                value: holding.id,
                label: holding.name,
            }));
        },
    },
    watch: {
        async holdingId(id) {
            if (!id) {
                return;
            }
            this.businessEntityId = null;
            this.businessEntityLoading = true;
            try {
                this.businessEntities = await this.$store.dispatch('getBusinessEntities', id);
            } catch (error) {
                this.setError(error);
            }
            this.businessEntityLoading = false;
        },
    },
    beforeMount() {
        this.platformName = this.platforms[0].name;
    },
    async mounted() {
        try {
            this.holdings = await this.$store.dispatch('getHoldings');
        } catch (error) {
            this.setError(error);
        }
    },
    methods: {
        close() {
            if (!this.currentContainer.id) {
                return this.$router.push({
                    name: ROUTE_NAMES.CONTAINER_PLACEHOLDER,
                });
            }

            return this.$router.push({
                name: ROUTE_NAMES.CONTAINER,
                params: { containerId: this.currentContainer.id },
            });
        },
        setPlatform(platformName) {
            this.platformName = platformName;
        },
        isPlatformActive(selectedPlatform) {
            return this.platformName === selectedPlatform;
        },
        saveContainer() {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;
            const containerToCreate = this.createContainer();
            const containerCreation = this.$store.dispatch('createContainer', { newContainer: containerToCreate });
            containerCreation
                .then((createdContainer) => {
                    this.$store.commit('setCurrentContainer', {});
                    this.$store.commit('selectedContainer', {
                        id: createdContainer.id,
                        name: containerToCreate.name,
                    });
                    this.$router.push({ name: ROUTE_NAMES.CONTAINER_TAG, params: { id: createdContainer.id } });
                })
                .catch((error) => this.setError(error))
                .finally(() => {
                    this.isSaving = false;
                });
        },
        createContainer() {
            const { name, country, platformName } = this;
            const businessEntityId = this.businessEntityId;
            const holdingId = this.holdingId;
            const platform = platformName.toUpperCase();
            return plainToClass(Container, { name, country, platform, businessEntityId, holdingId });
        },
    },
};
</script>
