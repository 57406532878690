<template>
    <div class="container-triggers">
        <OverviewHeader>
            Triggers
            <template v-if="!hasContainerError" #right-header-content>
                <SrButton type="secondary" :to="{ name: createTriggerRouteName }"> Create </SrButton>
            </template>
        </OverviewHeader>
        <ErrorBoxComponent v-model="error" outlined @close="resetError" />
        <TriggersTable v-if="!hasComponentErrors" :is-loading="isLoading" :triggers="triggers" />
        <router-view v-if="showModal" />
    </div>
</template>

<script>
import { SrButton } from '@ads/design-system';
import OverviewHeader from '@/views/OverviewHeader';
import TriggersTable from '@/views/TriggersTable';
import ContainerBasics from '@/views/ContainerBasics';
import { mapGetters } from 'vuex';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'ContainerTriggers',
    components: {
        ErrorBoxComponent,
        TriggersTable,
        OverviewHeader,
        SrButton,
    },
    mixins: [ContainerBasics],
    data() {
        return {
            createTriggerRouteName: ROUTE_NAMES.TRIGGER_CREATE,
        };
    },
    created() {
        this.loadTriggers();
    },
    computed: {
        ...mapGetters(['triggers']),
        showModal() {
            return this.$route.meta?.isModal;
        },
    },
    methods: {
        async loadTriggers() {
            this.$store.commit('setTriggers', []);
            await this.load('getAllTriggersByContainerId');
        },
    },
};
</script>
