import es6Renderer from 'express-es6-template-engine';
import Pixel from '@/models/Pixel';
import pixelTag from './templates/pixelTag.html';
import serverToServerTag from './templates/serverToServerTag.html';

export enum PIXEL_TYPE {
    CONVERSION = 'conversion',
    SERVING = 'serving',
    SEGMENT = 'segment',
}

export enum PIXEL_TAG_TYPE {
    IMAGE = 'img',
    JAVASCRIPT = 'js',
}

export default class PixelCodeLoader {
    loadFor(pixel: Pixel): string {
        const template = es6Renderer(this.getHtmlTemplate(pixel.isServerToServer), {
            template: true,
            locals: {
                htmlTag: this.getHtmlTag(pixel.tagType),
                tagType: pixel.tagType,
                id: pixel.id,
                businessEntityDspId: pixel.container.businessEntity.dspId,
            },
        });

        return template.trim();
    }

    private getHtmlTemplate(isServerToServer: boolean): string {
        if (isServerToServer) {
            return serverToServerTag;
        }
        return pixelTag;
    }

    private getHtmlTag(tagType: PIXEL_TAG_TYPE): string {
        if (tagType === PIXEL_TAG_TYPE.IMAGE) {
            return 'img';
        }
        return 'script';
    }
}
