<template>
    <SrNotification v-if="error" type="error" :title="error" v-bind="$attrs" @close="close" />
</template>

<script>
import { SrNotification } from '@ads/design-system';

export default {
    name: 'ErrorBoxComponent',
    components: {
        SrNotification,
    },
    model: {
        prop: 'error',
        event: 'input',
    },
    props: {
        error: {
            type: String,
            default: null,
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
