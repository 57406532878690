export default {
    currentContainer: {},
    currentContainerError: 'null',
    selectedContainer: {},
    isSidebarVisible: true,
    currentContainerTag: '',
    containers: [],
    businessEntities: [],
    pixels: [],
    pixelConversionTypes: [],
    pixelServingTypes: [],
    videoEventTypes: [],
    triggers: [],
    variables: [],
    currentTrigger: {},
    loadingStates: {
        currentContainer: null,
        currentContainerTag: null,
        containers: null,
        pixels: null,
        pixel: null,
        triggers: null,
        variables: null,
        currentTrigger: null,
        addingTriggerToPixel: null,
        publishContainer: null,
        saveContainer: null,
    },
};
