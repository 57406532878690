import Vuex from 'vuex';
import Vue from 'vue';

import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

const storeConfig = {
    state,
    mutations,
    actions,
    getters,
};

const store = new Vuex.Store(storeConfig);

store.dispatch('initialize');

export default store;
