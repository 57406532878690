export default {
    // loading Todo make one function
    currentContainerLoading: (state) => state.loadingStates.currentContainer,
    containersLoading: (state) => state.loadingStates.containers,
    triggersLoading: (state) => state.loadingStates.triggers,
    pixelsLoading: (state) => state.loadingStates.pixels,
    variablesLoading: (state) => state.loadingStates.variables,
    isPublishLoading: (state) => state.loadingStates.publishContainer,
    businessEntities: (state) => state.businessEntities,
    isSidebarVisible: (state) => state.isSidebarVisible,
    containerError: (state) => state.currentContainerError,
    hasContainerError: (state) => state.currentContainerError !== 'null',
    selectedContainer: (state) => state.selectedContainer,
    currentContainer: (state) => state.currentContainer,
    // do we really need this?
    currentContainerTag: (state) => state.currentContainerTag,

    // do we really need this?
    containers: (state) => state.containers,
    pixels: (state) => state.pixels,
    pixelConversionTypes: (state) => state.pixelConversionTypes,
    pixelServingTypes: (state) => state.pixelServingTypes,
    videoEventTypes: (state) => state.videoEventTypes,
    triggers: (state) => state.triggers,
    variables: (state) => state.variables,
    // should this be in store?
    isPixelFormDisplayed: (state) => state.isPixelFormDisplayed,

    pixelById: (state) => (id) => state.pixels.find((pixel) => pixel.id === id),
    triggerById: (state) => (id) => state.triggers.find((triggers) => triggers.id === id),

    isTriggerAssignedToPixel: () => (trigger, pixel) =>
        pixel.triggers.map((currentTrigger) => currentTrigger.id).includes(trigger.id),
};
