import { API_URL } from '@/config';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';

class HoldingRepository {
    private requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getAll() {
        const url = `${API_URL}/holdings`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'holding',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get(url, options);
    }
}

export default new HoldingRepository(new RequestService());
