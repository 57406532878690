<template>
    <VCol class="pl-0 pr-0">
        <SrDataPlaceholder
            v-if="!loading && pixels.length === 0"
            class="outlined pa-12"
            icon="pixelmanager"
            title="No Pixels available!"
            text=" Create pixel to be able to view live status, assign triggers and add variables."
            action-name="Create Pixel"
            action-route="create-pixel"
        >
            <SrButton type="secondary" class="mt-2" :to="{ name: createPixelRouteName }"> Create </SrButton>
        </SrDataPlaceholder>
        <SrTable v-else :items="pixels" :headers="viewModel.getHeaders()" :loading="loading" show-footer class="pixel-table">
            <template #pixelConversionType="pixel">
                {{ getConversionTypeName(pixel) }}
            </template>
            <template #name="pixel">
                <template v-if="isPixelTypeServing(pixel)">
                    {{ pixel.name }}
                </template>
                <template v-else>
                    <router-link :to="{ name: updatePixelRouteName, params: { pixelId: pixel.id } }" class="font-weight-bold">
                        {{ pixel.name }}
                    </router-link>
                </template>
            </template>
            <template #triggers="pixel">
                <button class="blue--text" @click="emitAssign(pixel.id)">
                    {{ viewModel.getTriggerNames(pixel) }}
                </button>
            </template>
            <template #isServerToServer="pixel">
                <span v-if="pixel.isServerToServer" class="brand-red--text font-weight-bold"> S2S </span>
            </template>
            <template #updatedAt="pixel">
                {{ new Date(pixel.updatedAt) | fromNow }}
            </template>
        </SrTable>
    </VCol>
</template>

<script>
import { castTimestampToReadableDiff, SrTable, SrDataPlaceholder, SrButton } from '@ads/design-system';
import PixelTableViewModel from '@/views/PixelTableViewModel';
import { PIXEL_TYPE } from '@/views/PixelForm/PixelCodeLoader';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'PixelTable',
    components: { SrTable, SrButton, SrDataPlaceholder },
    filters: {
        fromNow: castTimestampToReadableDiff,
    },
    props: {
        pixels: {
            type: Array,
            required: true,
        },
        containerId: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            viewModel: new PixelTableViewModel(),
            createPixelRouteName: ROUTE_NAMES.PIXEL_CREATE,
            updatePixelRouteName: ROUTE_NAMES.PIXEL_UPDATE,
        };
    },
    computed: {
        currentContainer() {
            return this.$store.getters.currentContainer;
        },
        businessEntities() {
            return this.$store.getters.businessEntities;
        },
    },
    methods: {
        getConversionTypeName(pixel) {
            if (pixel.pixelType !== PIXEL_TYPE.CONVERSION) {
                return '-';
            }

            return this.viewModel.getConversionTypeName(pixel, this.$store.getters.pixelConversionTypes);
        },
        isPixelTypeServing({ pixelType }) {
            return pixelType === PIXEL_TYPE.SERVING;
        },
        emitAssign(pixelId) {
            this.$emit('assignTrigger', pixelId);
        },
    },
};
</script>
