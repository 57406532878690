<template>
    <div class="pixel-manager-mf" data-app>
        <AppLayout>
            <router-view />
        </AppLayout>
    </div>
</template>

<script>
import AppLayout from '@/views/layouts/AppLayout';

export default {
    name: 'App',
    components: { AppLayout },
};
</script>
