import Pixel from '@/models/Pixel';
import PixelConversionType from '@/models/PixelConversionType';

interface IHeaderOption {
    text: string;
    value: string;
    align: string;
    sortable: boolean;
}

const headers: IHeaderOption[] = [
    {
        text: 'Conversion Type',
        value: 'pixelConversionType',
        align: 'left',
        sortable: true,
    },
    {
        text: 'ID',
        value: 'id',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Firing Triggers',
        value: 'triggers',
        align: 'left',
        sortable: false,
    },
    {
        text: 'S2S',
        value: 'isServerToServer',
        align: 'center',
        sortable: true,
    },
    {
        text: 'Last Edited',
        value: 'updatedAt',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Actions',
        value: 'options',
        align: 'right',
        sortable: false,
    },
];
export default class PixelTableViewModel {
    getHeaders(): IHeaderOption[] {
        return headers;
    }

    getConversionTypeName(pixel: Pixel, pixelConversionTypes: PixelConversionType[]): string {
        const conversionType = pixelConversionTypes.find((obj) => obj.id === pixel.pixelConversionTypeId);

        if (!conversionType) {
            return '';
        }

        return conversionType.name;
    }

    getTriggerNames(pixel): string {
        if (pixel.triggers.length === 0) {
            return 'Unassigned';
        }
        return pixel.triggers.map((trigger) => trigger.name).join(', ');
    }
}
