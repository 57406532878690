export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            optionsAreOpened: false,
            isShowingArchiveModal: false,
            isShowingDeleteModal: false,
        };
    },
    methods: {
        showDeleteModal() {
            this.isShowingDeleteModal = true;
        },
        closeDeleteModal() {
            this.isShowingDeleteModal = false;
        },
        emitDataDeletion() {
            this.$emit('delete');
        },
    },
};
