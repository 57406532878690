<template>
    <div class="d-flex flex-wrap mt-10">
        <SrSelect
            v-model="predicate.filterCriteriaId"
            class="mr-3 predicate-input"
            :items="filterCriteria"
            placeholder="Page URL"
            hide-details
            attach
        />
        <SrSelect
            v-model="predicate.predicateDefinitionId"
            class="mr-3 predicate-input"
            :items="predicateDefinitions"
            placeholder="equals"
            hide-details
            attach
        />
        <SrInput v-model="predicate.parameterValue.value" hide-details class="predicate-input" />
        <div class="d-flex align-center ml-3">
            <div role="button" class="add-predicate" @click="addPredicate">+</div>
        </div>
    </div>
</template>

<script>
import { SrSelect, SrInput } from '@ads/design-system';
import AtomicPredicate from './AtomicPredicate';

export default {
    name: 'PredicateItemConfigurator',
    components: {
        SrSelect,
        SrInput,
    },
    props: {
        value: {
            type: AtomicPredicate,
            required: true,
        },
        filterCriteria: {
            type: Array,
            default: () => [],
        },
        predicateDefinitions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            predicate: this.value,
        };
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.predicate = value;
            },
        },
        predicate: {
            deep: true,
            handler(value) {
                this.$emit('update', value);
            },
        },
    },
    methods: {
        addPredicate() {
            this.$emit('add-predicate');
        },
    },
};
</script>
