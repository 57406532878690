import { API_URL } from '@/config';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';

class AdvertiserRepository {
    private requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getAll(holdingId: number) {
        const url = `${API_URL}/holdings/${holdingId}/business-entities`;
        const options: IRequestOptions = {
            domain: 'pixel-manager',
            resource: 'business-entity',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get(url, options);
    }
}

export default new AdvertiserRepository(new RequestService());
