import { mapGetters } from 'vuex';
import ErrorBoxMixin from '@/views/ErrorBoxMixin';

export default {
    name: 'ContainerBasics',
    props: {
        containerId: {
            type: [Number, String],
            required: true,
        },
    },
    mixins: [ErrorBoxMixin],
    computed: {
        ...mapGetters(['hasContainerError', 'currentContainer', 'currentContainerLoading']),
        containerWasChanged() {
            return !(this.currentContainer.id === this.containerId && !this.currentContainerLoading);
        },
        hasComponentErrors() {
            return this.hasContainerError || this.error;
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    watch: {
        containerId: {
            immediate: true,
            handler() {
                this.loadContainer();
            },
        },
    },
    methods: {
        loadContainer() {
            if (!this.containerWasChanged || this.hasContainerError) {
                return;
            }
            this.$store.dispatch('fetchContainerById', { id: this.containerId });
        },
        async load(type) {
            if (this.hasContainerError) {
                return;
            }

            this.isLoading = true;
            let loadedContainerId = null;
            try {
                const { containerId } = await this.$store.dispatch(type, this.containerId);
                loadedContainerId = containerId;
            } catch (error) {
                if (this.currentContainer.id) {
                    this.setError(error);
                }
                this.isLoading = false;
            }
            if (this.containerId === loadedContainerId) {
                this.isLoading = false;
            }
        },
    },
};
