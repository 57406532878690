<template>
    <div class="overview-header">
        <div class="d-flex align-top">
            <SrHeadline weight="bold" class="ma-0 ml-2 headline">
                <slot />
            </SrHeadline>
            <VCol cols="5" class="ml-auto pr-0 pt-0 flex-row-reverse text-right">
                <slot name="right-header-content" />
            </VCol>
        </div>
        <VDivider class="mt-5 mb-5" />
    </div>
</template>

<script>
import { SrHeadline } from '@ads/design-system';

export default {
    name: 'OverviewHeader',
    components: {
        SrHeadline,
    },
};
</script>
