<template>
    <VContainer class="pt-1 page-layout__container">
        <VRow>
            <VCol class="pl-10 page-layout__header align-center d-flex pr-10">
                <slot name="header-content" />
            </VCol>
        </VRow>
        <VRow>
            <slot name="header-loading" />
        </VRow>
        <VRow class="pb-5 d-flex justify-center page-layout__content">
            <VCol cols="11">
                <slot />
            </VCol>
        </VRow>
        <v-footer class="row page-layout__footer">
            <slot name="footer-content" />
        </v-footer>
    </VContainer>
</template>

<script>
export default {
    name: 'PageLayout',
};
</script>
