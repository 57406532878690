export default {
    data() {
        return {
            error: null,
        };
    },
    methods: {
        setError(error) {
            if (typeof error === 'string') {
                this.error = error;
                return;
            }
            if (error !== null && Object.keys(error).length === 0) {
                this.error = 'Unexpected error occurred.';
                return;
            }

            this.error = error?.response?.message || JSON.stringify(error);
        },
        resetError() {
            this.error = null;
        },
    },
};
