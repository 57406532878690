<template>
    <div>
        <div v-if="containersLoading" class="pa-3">
            <VSkeletonLoader type="button" class="mt-2 mb-8" />
            <VSkeletonLoader type="heading" class="mb-8" />
            <div class="d-flex mb-10 pb-10">
                <VSkeletonLoader type="button" class="mr-10" />
                <VSkeletonLoader type="button" />
            </div>
            <VSkeletonLoader class="mt-10" type="table-thead" />
            <VSkeletonLoader type="table-row@6" />
        </div>
        <ModalPageLayout v-else-if="!containersAvailable" persistent>
            <PageLayout>
                <template #header-content>
                    <h3 class="brand-blue--text font-weight-regular">Pixel Manager</h3>
                    <div class="close-icon-wrapper" @click="close">
                        <SrIcon class="pa-1" icon="close" size="xs" @click="close" />
                    </div>
                </template>
                <Placeholder :title="title">
                    <p>
                        To start using our Pixel Manager you will need to first create a container and then install our Pixel
                        Manager on your website.
                    </p>
                    <p>It will start tracking page view events right after the code is installed on the website.</p>
                    <SrButton type="secondary" class="mt-2" :to="{ name: createContainerRouteName }"> Create </SrButton>
                </Placeholder>
            </PageLayout>
        </ModalPageLayout>
    </div>
</template>

<script>
import { copyText } from '@/config';
import Placeholder from '@/views/Placeholder';
import ModalPageLayout from '@/views/layouts/ModalPageLayout';
import PageLayout from '@/views/layouts/PageLayout';
import { SrIcon, SrButton } from '@ads/design-system';
import { ROUTE_NAMES } from '@/routes/routeNames';

export default {
    name: 'ContainerPlaceholder',
    components: { PageLayout, ModalPageLayout, Placeholder, SrButton, SrIcon },
    data() {
        return {
            show: true,
            title: copyText.en.containerPlaceholder.title,
            createContainerRouteName: ROUTE_NAMES.CONTAINER_CREATE,
        };
    },
    computed: {
        containers() {
            return this.$store.getters.containers;
        },
        containersAvailable() {
            return this.containers.length !== 0;
        },
        containersLoading() {
            return this.$store.getters.containersLoading;
        },
        userNavigatedAway() {
            return this.$router.currentRoute.name !== ROUTE_NAMES.CONTAINER_PLACEHOLDER;
        },
    },
    async created() {
        await this.loadContainers();
        this.redirectToContainerIfPossible();
    },
    methods: {
        close() {
            location.assign('/onboarding');
        },
        async loadContainers() {
            await this.$store
                .dispatch('getAllContainers')
                .catch((error) => this.$store.commit('setCurrentContainerError', error));
        },
        redirectToContainerIfPossible() {
            if (!this.containersAvailable || this.userNavigatedAway) {
                return;
            }
            const latestContainer = this.containers[this.containers.length - 1];
            this.$router.push({ name: ROUTE_NAMES.CONTAINER, params: { containerId: latestContainer.id } });
        },
    },
};
</script>
