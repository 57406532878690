<template>
    <div>
        <OverviewHeader>
            Pixels
            <template v-if="!hasContainerError" #right-header-content>
                <DropdownButton :items="bulkCreateActions" :loading="bulkCreateLoading" @clicked="dropdownClicked" />
                <SrButton class="ml-5" type="secondary" :to="{ name: createPixelRouteName }"> Create </SrButton>
            </template>
        </OverviewHeader>
        <ErrorBoxComponent v-model="error" outlined @close="resetError" />
        <PixelTable
            v-if="!hasComponentErrors"
            :container-id="containerId"
            :pixels="pixels"
            :loading="isLoading"
            @assignTrigger="navigateToTriggers"
        />
        <router-view v-if="showModal" />
    </div>
</template>

<script>
import { SrButton } from '@ads/design-system';
import PixelTable from '@/views/PixelTable';
import OverviewHeader from '@/views/OverviewHeader';
import ContainerBasics from '@/views/ContainerBasics';
import { mapGetters } from 'vuex';
import pixelRepository from '@/store/PixelRepository';
import ErrorBoxComponent from '@/views/ErrorBoxComponent';
import { ROUTE_NAMES } from '@/routes/routeNames';
import DropdownButton from './DropdownButton';
import { PIXEL_TYPE } from './PixelForm/PixelCodeLoader';
import { PIXEL_CONVERSION_TYPE_IDS } from './PixelForm/types';

export default {
    name: 'ContainerPixels',
    components: {
        ErrorBoxComponent,
        OverviewHeader,
        PixelTable,
        SrButton,
        DropdownButton,
    },
    mixins: [ContainerBasics],
    data() {
        return {
            bulkCreateLoading: false,
            createPixelRouteName: ROUTE_NAMES.PIXEL_CREATE,
        };
    },
    computed: {
        ...mapGetters(['pixels', 'currentContainer']),
        showModal() {
            return this.$route.meta?.isModal;
        },
        bulkCreateActions() {
            return [
                {
                    title: 'Bulk create video',
                    icon: 'play',
                    action: 'create-bulk-video',
                    disabled: this.areBulkVideoPixelsCreated(),
                },
                {
                    title: 'Bulk create DD',
                    icon: 'DynamicDisplay',
                    action: 'create-bulk-serving',
                    disabled: this.areBulkServingPixelsCreated(),
                },
            ];
        },
    },
    watch: {
        containerId: {
            immediate: true,
            handler() {
                this.loadPixels();
            },
        },
    },
    methods: {
        async loadPixels() {
            this.$store.commit('setPixels', []);
            this.isLoading = true;
            const { containerId } = await this.$store.dispatch('fetchPixelsForContainer', this.containerId);
            if (this.containerId === containerId) {
                this.isLoading = false;
            }
            await this.load('fetchPixelsForContainer');
        },
        navigateToTriggers(pixelId) {
            const { params } = this.$router;
            this.$router.push({ name: ROUTE_NAMES.TRIGGER_ASSIGN, params: { ...params, pixelId } });
        },
        areBulkServingPixelsCreated() {
            const servingPixels = this.pixels.filter(({ pixelType }) => pixelType === PIXEL_TYPE.SERVING);
            const pixelServingTypesLength = this.$store.getters.pixelServingTypes.length;
            return servingPixels.length === pixelServingTypesLength;
        },
        areBulkVideoPixelsCreated() {
            const videoAudiencePixels = this.pixels.filter(
                ({ pixelConversionTypeId }) => pixelConversionTypeId === PIXEL_CONVERSION_TYPE_IDS.VIDEO_AUDIENCE,
            );
            const videoEventTypeLength = this.$store.getters.videoEventTypes.length;
            return videoAudiencePixels.length === videoEventTypeLength;
        },
        async dropdownClicked(item) {
            try {
                this.bulkCreateLoading = true;

                switch (item.action) {
                    case 'create-bulk-serving':
                        await pixelRepository.bulkCreateServingPixels(this.containerId, this.currentContainer.businessEntityId);
                        await this.load('fetchPixelsForContainer');
                        break;
                    case 'create-bulk-video':
                        await pixelRepository.bulkCreateVideoPixels(this.containerId, this.currentContainer.businessEntityId);
                        await this.load('fetchPixelsForContainer');
                        break;
                    default:
                        break;
                }

                this.$store.dispatch('fetchPixelsForContainer', this.containerId);
            } catch (error) {
                this.setError(error);
            } finally {
                this.bulkCreateLoading = false;
            }
        },
    },
};
</script>
