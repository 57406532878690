export default {
    name: 'AbstractTriggerTypeConfig',
    data() {
        return {
            config: {},
        };
    },
    props: ['value'],
    computed: {
        isValid() {
            return true;
        },
    },
    mounted() {
        this.$emit('input', this.config);
    },
    watch: {
        config: {
            deep: true,
            handler() {
                this.$emit('input', this.config);
            },
        },
    },
};
