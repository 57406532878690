<template>
    <div>
        <SrMenu v-model="optionsAreOpened" attach offset-y left min-width="100">
            <template #activator="{ on, attrs }">
                <SrButton icon v-bind="attrs" v-on="on">
                    <SrIcon :active="optionsAreOpened" icon="more" size="xs" />
                </SrButton>
            </template>
            <SrList dense>
                <!-- Delete -->
                <SrListItem @click="showDeleteModal">
                    <SrListItemIcon>
                        <SrIcon icon="Trash" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle> Delete</SrListItemTitle>
                </SrListItem>
            </SrList>
        </SrMenu>
        <SingleDeleteModal
            :id="item.id"
            v-model="isShowingDeleteModal"
            :item-description="itemDescription"
            :callback="deleteTrigger"
            @cancel="closeDeleteModal"
            @completed="onDeletionCompleted"
        />
    </div>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon } from '@ads/design-system';
import SingleDeleteModal from '@/views/SingleDeleteModal';
import TableActionMixin from '@/views/mixins/TableActionMixin';

export default {
    name: 'TriggerTableActions',
    components: {
        SrButton,
        SrIcon,
        SrMenu,
        SrList,
        SrListItem,
        SrListItemTitle,
        SrListItemIcon,
        SingleDeleteModal,
    },
    mixins: [TableActionMixin],
    computed: {
        itemDescription() {
            return `Trigger: '${this.item.name}'`;
        },
    },
    methods: {
        onDeletionCompleted() {
            this.closeDeleteModal();
            this.emitDataDeletion();
        },
        async deleteTrigger(id) {
            const containerId = this.$store.getters.currentContainer.id;
            await this.$store
                .dispatch('deleteTrigger', {
                    id,
                    containerId,
                })
                .catch((error) => this.setError(error));
        },
    },
};
</script>
