import ContainerRepository from '@/store/ContainerRepository';
import AdvertiserRepository from '@/store/AdvertiserRepository';
import HoldingRepository from '@/store/HoldingRepository';
import TriggerRepository from '@/store/TriggerRepository';
import VariableRepository from '@/store/VariableRepository';
import PixelRepository from './PixelRepository';

export default {
    async initialize({ dispatch, commit }) {
        try {
            dispatch('fetchPixelsConversionTypes');
            dispatch('fetchPixelServingTypes');
            dispatch('fetchVideoEventTypes');
        } catch (error) {
            commit('setCurrentContainerError', error);
        }
    },

    async fetchContainerById({ commit, state }, { id }) {
        if (!state.selectedContainer.id) {
            commit('selectedContainer', { id });
        }

        let container = null;
        try {
            container = await ContainerRepository.getById(id);
            commit('setCurrentContainerError', null);
        } catch (error) {
            commit('setCurrentContainerError', error);
            return;
        }

        if (!state.selectedContainer.name) {
            commit('selectedContainer', { id: container.id, name: container.name });
        }
        if (state.selectedContainer.id === container.id) {
            commit('setCurrentContainer', container);
        }
        commit('setLoadingState', { key: 'currentContainer', isLoading: false });
    },

    async getAllContainers({ commit }) {
        commit('setLoadingState', { key: 'containers', isLoading: true });

        const containers = await ContainerRepository.getAll();

        commit('setLoadingState', { key: 'containers', isLoading: false });

        commit('setContainers', containers);
        return containers;
    },

    async getBusinessEntities({ commit }, holdingId) {
        const businessEntities = await AdvertiserRepository.getAll(holdingId);
        commit('setBusinessEntities', businessEntities);
        return businessEntities;
    },

    async getHoldings() {
        return await HoldingRepository.getAll();
    },

    async createContainer({ commit }, { newContainer }) {
        const container = await ContainerRepository.create(newContainer);

        commit('addContainer', container);
        return container;
    },

    async saveContainer({ commit }, { container, containerId }) {
        commit('setLoadingState', { key: 'saveContainer', isLoading: true });
        await ContainerRepository.save(container, containerId);
        commit('setLoadingState', { key: 'saveContainer', isLoading: false });
    },

    async getContainerTag({ commit }, { id }) {
        commit('setLoadingState', { key: 'containerTag', isLoading: true });

        const containerTag = await ContainerRepository.getTag(id);

        commit('setLoadingState', { key: 'containerTag', isLoading: false });

        commit('setCurrentContainerTag', containerTag);
    },

    async publishContainer({ commit, dispatch }, containerId) {
        commit('setLoadingState', { key: 'publishContainer', isLoading: true });

        await ContainerRepository.publish(containerId);
        await dispatch('fetchContainerById', { id: containerId });

        commit('setLoadingState', { key: 'publishContainer', isLoading: false });
    },

    async saveTriggerWithContainerId(_, { trigger, containerId }) {
        // set empty config as most trigger types do not have configuration options in frontend yet
        if (!trigger.config) {
            trigger.config = {};
        }
        return TriggerRepository.saveWithContainerId(trigger, containerId);
    },

    async deleteTrigger({ commit, state }, { id, containerId }) {
        const deleteResult = await TriggerRepository.deleteByTriggerIdAndContainerId(id, containerId);

        if (deleteResult.status) {
            const oldTriggers = state.triggers;
            const newTriggers = oldTriggers.filter((trigger) => trigger.id !== id);

            commit('setTriggers', newTriggers);
        } else {
            commit('setCurrentContainerError', deleteResult.message);
        }
    },

    async saveVariableWithContainerId(_, { variable, containerId }) {
        return VariableRepository.saveWithContainerId(variable, containerId);
    },

    async savePixel({ commit }, { pixel, containerId }) {
        commit('setLoadingState', { key: 'pixel', isLoading: true });
        const savedPixel = PixelRepository.save(pixel, containerId);
        commit('setLoadingState', { key: 'pixel', isLoading: false });

        return savedPixel;
    },

    async deletePixel({ commit, dispatch }, { id, containerId }) {
        commit('setLoadingState', { key: 'pixel', isLoading: true });
        await PixelRepository.delete(id, containerId);
        // update container information
        await dispatch('fetchContainerById', { id: containerId });
        commit('setLoadingState', { key: 'pixel', isLoading: false });
    },

    async getAllTriggersByContainerId({ state, commit }, containerId) {
        commit('setLoadingState', { key: 'triggers', isLoading: true });
        const triggers = await TriggerRepository.getAllByContainerId(containerId);
        if (state.selectedContainer.id === containerId) {
            commit('setTriggers', triggers);
        }
        commit('setLoadingState', { key: 'triggers', isLoading: false });
        return {
            containerId,
            triggers,
        };
    },

    async fetchVariablesForContainer({ commit }, containerId) {
        commit('setLoadingState', { key: 'variables', isLoading: true });
        commit('setVariables', await VariableRepository.getAllByContainerId(containerId));
        commit('setLoadingState', { key: 'variables', isLoading: false });
    },

    async fetchPixelsForContainer({ state, commit }, containerId) {
        commit('setLoadingState', { key: 'pixels', isLoading: true });

        const pixels = await PixelRepository.getPixelsForContainer(containerId);

        commit('setLoadingState', { key: 'pixels', isLoading: false });
        if (state.selectedContainer.id === containerId) {
            commit('setPixels', pixels);
        }
        return {
            containerId,
            pixels,
        };
    },

    async fetchPixelsConversionTypes({ commit }) {
        const pixelConversionTypes = await PixelRepository.getPixelConversionTypes();

        commit('setPixelConversionTypes', pixelConversionTypes);
    },

    async fetchPixelServingTypes({ commit }) {
        const pixelServingTypes = await PixelRepository.getPixelServingTypes();

        commit('setPixelServingTypes', pixelServingTypes);
    },

    async fetchVideoEventTypes({ commit }) {
        const videoEventTypes = await PixelRepository.getVideoEventTypes();

        commit('setVideoEventTypes', videoEventTypes);
    },

    async assignTriggerToPixel(_, { pixelId, triggerId, containerId }) {
        await PixelRepository.assignTrigger(pixelId, triggerId, containerId);
    },

    async unassignTriggerFromPixel(_, { pixelId, triggerId, containerId }) {
        await PixelRepository.unassignTrigger(pixelId, triggerId, containerId);
    },
};
